.m-section{
  padding: 8.75em 0;
}

.m-section2{
  padding: 4.375em 0;
}


._dark-block{
  background-color: $black;
  color: $white;
}

.company-stat{
  &__inner{
    display: grid;
    grid-template-columns: 49.25em calc(100% - 51.75em);
    gap: 2em 2.5em;
    align-items: center;
  }
  &__img{
    img{
      width: 100%;
      display: block;
    }
  }
  &__items{
    @include grid100gap(5em);
  }
}

.stat-item{
  @include grid100gap(.5em);
  &__num{
    color: $primary;
  }
}

.news{
  background-color: $white;
  &--bg{
    background-color: $lightGrey;
  }
  &__items{
    margin-top: 3em;
    @include grid100gap(3.5em);
    &:first-child{
      margin-top: 0;
    }
  }
}

.article .body-text{
  max-width: 71.5em;
  width: 100%;
  margin: 0 auto;
}

.news-card{
  display: grid;
  grid-template-columns: 34.5em 34.5em;
  gap: 2em;
  align-items: center;
  justify-content: space-between;
  &__img{
    @include imgCover(49.3%);
  }
  &__text{
    @include grid100gap(.5em);
    margin-top: .75em;
  }
  &__desc{
    @include truncate(2);
  }
  &__nav{
    display: flex;
    margin-top: 2em;
  }
}

.info{
  background-color: $white;
  @include grid100gap(8.75em);
}

.info-card{
  overflow: hidden;
  &__inner{
    display: grid;
    grid-template-columns: 34.5em calc(100% - 37em);
    align-items: center;
    gap: 2em 2.5em;
  }
  &__desc{
    margin-top: 2em;
  }
  &__nav{
    display: flex;
    margin-top: 2em;
  }
  &__img{
    width: calc(100% + 3.75em);
    overflow: hidden;
    img{
      width: 100%;
    }
    @media screen and (min-width: 1501px){
      width: calc(100% + 3.75em + ((100vw - 1500px) / 2));
    }
  }
  &:nth-child(even){
    @media screen and (min-width: 993px){
      .info-card__inner{
        grid-template-columns: calc(100% - 37em) 34.5em;
      }
      .info-card__img{
        order: -1;
        margin-left: -3.75em;
        @media screen and (min-width: 1501px){
          margin-left: calc((3.75em + (100vw - 1500px) / 2) * -1);
        }
      }
    }
  }
}


@media screen and (max-width: 1500px){
  .company-stat{
    &__inner{
      grid-template-columns: 41.25em calc(100% - 43.75em);
    }
  }

  .info-card{
    &__img{
      @media screen and (min-width: 1281px){
        width: calc(100% + 3.75em + ((100vw - 1280px) / 2));
      }
    }
    &:nth-child(even){
      @media screen and (min-width: 993px){
        .info-card__img{
          @media screen and (min-width: 1281px){
            margin-left: calc((3.75em + (100vw - 1280px) / 2) * -1);
          }
        }
      }
    }
  }
}


@media screen and (max-width: 992px){
  .m-section{
    padding: 6em 0;
  }

  .m-section2{
    padding: 3em 0;
  }

  .company-stat{
    &__inner{
      grid-template-columns: 100%;
    }
    &__items{
      gap: 2em;
    }
  }

  .news{
    &__top{
      transform: unset !important;
    }
    &__items{
      margin-top: 2em;
      gap: 2em;
    }
  }

  .news-card{
    grid-template-columns: 100%;
    &__img{
      order: -1;
    }
    &__nav{
      margin-top: 1.5em;
    }
  }

  .info{
    padding-top: 6em;
    gap: 6em;
  }

  .info-card{
    &__inner{
      grid-template-columns: 100%;
    }
    &__desc{
      margin-top: 1.5em;
    }
    &__img{
      width: 100%;
    }
  }
}


@media screen and (max-width: 576px){
  .m-section,
  .m-section2{
    padding: 2.25em 0;
  }

  .news{
    &__inner{
      position: relative;
      padding-bottom: 5.125em;
    }
    &__link{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }

  .news-card{
    gap: 1.5em;
    &__btn{
      width: 100%;
    }
  }

  .info{
    padding-top: 3.5em;
    gap: 3.5em;
  }

  .info-card{
    &__desc{
      margin-top: 1em;
    }
    &__link{
      width: 100%;
    }
  }
}
