.product-oe{
  margin-top: 2.5em;
  &__top{
    border-bottom: $border;
    padding-bottom: .5em;
  }
  .m-select{
    min-width: 12.5em;
  }
  .product-usability{
    margin-top: 1.5em;
  }
}

.m-select{
  .nice-select{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0;
    white-space: nowrap;
    height: 2.25em;
    padding: 0 .75em;
    background-color: $lightGrey !important;
    border: 1px solid $lightGrey;
    width: auto;
    min-width: 100%;
    font-size: 1em;
    &:focus{
      border-color: $lightBlue;
    }
    &.open{
      &:before{
        transform: rotate(-180deg);
      }
    }
    .current{
      font-size: .875em;
      line-height: 143%;
      letter-spacing: 0.01em;
    }
    &:before{
      content: '';
      order: 3;
      flex-shrink: 0;
      width: 1.25em;
      height: 1.25em;
      background-image: url(../img/arrow-bottom.svg);
      @include bgImgSize(100%);
      margin-left: 1em;
      transition: all .3s;
    }
    .list{;
      border-radius: 0;
      min-width: 100%;
      width: auto;
      border: none;
      background: #FFF;
      box-shadow: 0 .25em .5em rgba(0, 0, 0, 0.16);
      max-height: 14.875em;
      overflow-y: auto;
      z-index: 5;
      @include scroll;
    }
    .option{
      height: 2.57em;
      font-size: .875em;
      line-height: 143%;
      letter-spacing: 0.01em;
      padding: 0 .86em;
      display: flex;
      align-items: center;
      color: $black;
      border: none;
      &:last-child{
        border-bottom: none;
      }
      &.disabled{
        display: none;
      }
      &:hover,
      &.focus,
      &.selected.focus{
        background-color: $primary;
        border-color: $primary;
        font-weight: 400;
        color: #fff;
      }
      &.selected{
        display: none;
      }
    }
  }
}

.usability-modal{
  &__filter{
    display: grid;
    grid-template-columns: 12.5em calc(100% - 13.5em);
    gap: 1em;
    margin-top: 1.5em;
  }
}

.m-search{
  position: relative;
  input{
    background-color: $lightGrey;
    border: 1px solid $lightGrey;
    height: 2.57em;
    width: 100%;
    border-radius: 0;
    padding: 0 2.57em 0 .86em;
    font-size: .875em;
    font-weight: 400;
    letter-spacing: 0.01em;
    color: $black;
    &::placeholder{
      color: $black-40;
    }
    &:focus{
      border-color: $lightBlue;
    }
  }
  &__btn{
    position: absolute;
    right: .5em;
    top: 50%;
    transform: translateY(-50%);
    @include center;
    svg{
      width: 1.25em;
      height: 1.25em;
      fill: $black-40;
    }
    &:hover{
      svg{
        fill: $black;
      }
    }
  }
  ._view2{
    opacity: 0;
    pointer-events: none;
  }
  &._filled{
    ._view1{
      pointer-events: none;
      opacity: 0;
    }
    ._view2{
      opacity: 1;
      pointer-events: unset;
    }
  }
}


@media screen and (max-width: 1500px){

}


@media screen and (max-width: 992px){
  .product-oe{
    margin-top: 1.5em;
  }
}


@media screen and (max-width: 576px){
  .product-oe{
    &__top{
      @include grid100gap(1em);
    }
    .m-select{
      width: 100%;
    }
  }

  .usability-modal{
    &__filter{
      grid-template-columns: 100%;
    }
  }
}
