.filter{
  margin-top: 1em;
  &__nav{
    margin-top: -.75em;
    position: sticky;
    bottom: 0;
    padding-top: 1em;
    padding-bottom: 1em;
    background-color: $white;
    z-index: 3;
  }
}

.filter-group{
  padding: 1em 0;
  border-top: $border;
  &._active{
    .filter-group__btn{
      svg{
        transform: rotate(-180deg);
      }
    }
  }
  &__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  &__btn{
    svg{
      width: 1.25em;
      height: 1.25em;
    }
  }
  &__title{
    font-size: .875em;
    line-height: 143%;
    letter-spacing: 0.01em;
    font-weight: 700;
  }
  &__bottom{
    margin-top: .5em;
    display: none;
  }
  .filter-select{
    margin-top: 1em;
  }
  &__checks{
    &-group{
      .more-btn{
        margin-top: .5em;
        display: none;
        svg{
          transform: rotate(90deg);
        }
        i{
          font-style: normal;
        }
      }
      &._a-lot-of{
        .more-btn{
          display: flex;
        }
      }
      &:not(._show-all){
        .m-check:nth-child(5)~.m-check{
          display: none;
        }
      }
      &._show-all{
        .more-btn{
          ._view1{
            display: none;
          }
          ._view2{
            display: block;
          }
          svg{
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
}

.filter-select{
  position: relative;
  &._open{
    .filter-select__show:after{
      transform: rotate(-180deg);
    }
    .filter-select__dropdown{
      opacity: 1;
      pointer-events: unset;
    }
  }
  &__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .5em;
  }
  &__clear{
    &:hover{
      color: $lightBlue;
    }
    &:active{
      color: $primary;
    }
  }
  &__show{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.25em;
    padding: 0 .75em;
    background-color: $lightGrey;
    &:after{
      transition: all .3s;
      content: '';
      width: 1.25em;
      height: 1.25em;
      background-image: url(../img/arrow-bottom.svg);
      @include bgImgSize(100%);
      margin-left: .5em;
    }
  }
  &__dropdown{
    transition: all .3s;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: .25em;
    z-index: 5;
    background-color: $white;
    box-shadow: 0 .25em .5em rgba(0, 0, 0, 0.16);
    @include scroll;
    overflow-y: auto;
    max-height: 16em;
  }
  &__search{
    position: relative;
    img{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: .75em;
      width: 1.25em;
    }
    input{
      font-family: $font1;
      border: none;
      border-bottom: $border;
      height: 2.57em;
      width: 100%;
      border-radius: 0;
      padding: 0 2.57em 0 .86em;
      font-size: .875em;
      font-weight: 400;
      letter-spacing: 0.01em;
      color: $black;
      &::placeholder{
        color: $black-40;
      }
    }
  }
  .m-check{
    label{
      padding: .5em 1em .5em 2.25em;
      min-height: 2.25em;
      &:before{
        left: .875em;
      }
    }
  }
}

.selected-filters{
  display: flex;
  margin-bottom: 1em;
  flex-wrap: wrap;
  margin-top: -.5em;
  &__item{
    margin: .5em .75em 0 0;
    display: flex;
    align-items: center;
    padding: 0 .75em;
    height: 1.75em;
    background-color: $lightGrey;
    span{
      font-size: .875em;
      line-height: 143%;
      letter-spacing: 0.01em;
    }
  }
  &__clear{
    width: .75em;
    @include center;
    margin-left: .5em;
    svg{
      width: 100%;
      height: .75em;
    }
  }
}


@media screen and (max-width: 1500px){

}


@media screen and (max-width: 992px){
  .selected-filters{
    flex-wrap: nowrap;
    margin: 1.5em 0 1em -1.5em;
    width: calc(100% + 3em);
    padding: 0 1.5em .5em;
    overflow-x: auto;
    &__item{
      margin-top: 0;
      flex-shrink: 0;
    }
  }

  .filter{
    &__nav{
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      margin: 0;
      box-shadow: 1px 1px 16px 0 rgba(0, 10, 18, 0.16), 1px 1px 2px 0 rgba(0, 10, 18, 0.01);
      background-color: $white;
      padding: 1em 1.5em;
      grid-template-columns: repeat(2,calc(50% - .5em));
      .m-btn{
        padding: 0;
      }
      .m-btn-blue{
        order: 2;
      }
    }
  }
}


@media screen and (max-width: 576px){
  .selected-filters{
    width: calc(100% + 2em);
    margin-left: -1em;
    padding-left: 1em;
    padding-right: 1em;
  }

  .filter{
    &__nav{
      padding: 1em;
    }
  }
}
