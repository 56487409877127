.error404{
  padding-top: 4.25em;
  //height: $vh;
  flex-grow: 1;
  display: flex;
  color: $white;
  &__wrap{
    width: 100%;
    flex-grow: 1;
    background-image: url(../img/error404.jpg);
    background-size: cover;
    background-position: center;
    padding: 10.25em 0;
  }
  &__content{
    width: 23.375em;
    max-width: 100%;
    margin-left: auto;
    text-align: center;
    @include grid100gap(.75em);
  }
  &__num{
    font-size: 11.25em;
    line-height: 108%;
    font-weight: 800;
  }
  .m-nav{
    margin-top: 1.25em;
    justify-content: center;
  }
}


@media screen and (max-width: 1500px){

}


@media screen and (max-width: 992px){
  .error404{
    padding-top: 3.75em;
    &__wrap{
      padding: 4.5em 0;
    }
    &__content{
      margin-right: auto;
    }
  }

}


@media screen and (max-width: 576px){
  .error404{
    &__wrap{
      padding: 2.25em 0;
      background-image: url(../img/error404-mob.jpg);
    }
    &__content{
      gap: .5em;
    }
    &__num{
      font-size: 6em;
    }
    .m-nav{
      margin-top: .5em;
      width: auto;
    }
  }
}
