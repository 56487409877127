.grid{
  display: grid;
  gap: 1em;
  grid-template-columns: 100%;
}

.grid2{
  grid-template-columns: repeat(2,calc(50% - .5em));
}

.grid3{
  grid-template-columns: repeat(3,calc(100% / 3 - 2em / 3));
}

.grid4{
  grid-template-columns: repeat(4,calc(25% - 3em / 4));
}

.grid5{
  grid-template-columns: repeat(5,calc(20% - 4em / 5));
}

.grid6{
  grid-template-columns: repeat(6,calc(100% / 6 - 5em / 6));
}


@media screen and (max-width: 992px){
  .grid-tablet{
    grid-template-columns: 100%;
  }

  .grid-tablet2{
    grid-template-columns: repeat(2,calc(50% - .5em));
  }

  .grid-tablet3{
    grid-template-columns: repeat(3,calc(100% / 3 - 2em / 3));
  }

  .grid-tablet4{
    grid-template-columns: repeat(4,calc(25% - 3em / 4));
  }
}


@media screen and (max-width: 576px){
  .grid-mob{
    grid-template-columns: 100%;
  }

  .grid-mob2{
    grid-template-columns: repeat(2,calc(50% - .5em));
  }
}
