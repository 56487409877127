.product-categories{
  background: #f4f8fe;
}

.category-slider2{
  margin-top: 3em;
  .slider__wrap{
    overflow: hidden;
  }
  .swiper{
    &-container{
      width: calc(100% + 1em);
    }
    &-slide{
      width: 25%;
      padding-right: 1em;
    }
    &-pagination{
      position: static;
      margin-top: 2em;
      &-bullet:not(.swiper-pagination-bullet-active){
        background-color: $black-40;
      }
    }
  }
}

.production-cycle{
  background: $gradient;
  color: $white;
  &__inner{
    padding: 4.375em 0;
    position: relative;
  }
  &__content{
    max-width: 46.625em;
  }
  &__text{
    margin-top: 1em;
    ul{
      padding: 0;
      display: grid;
      grid-template-columns: repeat(2,calc(50% - .5em));
      gap: 0 1em;
    }
    p{
      margin-bottom: .5em;
    }
  }
  &__img{
    position: absolute;
    right: -3.75em;
    bottom: 0;
    width: 41.25em;
  }
}

.product-advantages4{
  .advantages__grid{
    margin-top: 3em;
  }
  @media screen and (min-width: 993px){
    .advantages__img{
      grid-row: 1/4;
    }
  }
  @media screen and (min-width: 577px){
    .advantage-item{
      &:nth-child(5){
        grid-column: span 2;
      }
    }
  }
}


@media screen and (max-width: 1500px){
  .production-cycle{
    &__content{
      max-width: 41.25em;
    }
  }
}


@media screen and (max-width: 992px){
  .category-slider2{
    margin-top: 2em;
    .swiper{
      &-slide{
        width: calc(100% / 3);
      }
      &-pagination{
        margin-top: 1.5em;
      }
    }
  }

  .production-cycle{
    &__inner{
      padding: 3em 0;
    }
    &__content{
      max-width: 100%;
    }
    &__img{
      display: none;
    }
  }

  .product-advantages4{
    .advantages__grid{
      margin-top: 2em;
    }
  }
}


@media screen and (max-width: 576px){
  .product-categories{
    overflow: hidden;
  }

  .category-slider2{
    .slider__wrap{
      overflow: unset;
    }
    .swiper{
      &-slide{
        width: 15.3125em;
      }
    }
  }

  .production-cycle{
    &__inner{
      padding: 2.25em 0;
    }
    &__text{
      ul{
        grid-template-columns: 100%;
      }
    }
  }
}
