.manufacture2{
  .manufacture__inner{
    background-image: url(../img/manufacture2.jpg);
  }
  .m-nav{
    margin: 0;
  }
  &__items{
    display: grid;
    grid-template-columns: repeat(2,calc(50% - 1.5em));
    gap: 3em;
  }
  &__item{
    text-align: center;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      height: 100%;
      width: 1px;
      background-color: $white-12;
      margin-left: 1.5em;
    }
    .h2{
      color: $primary;
    }
    &-desc{
      margin-top: .5em;
    }
  }
}

.tec-doc-provider{
  background: $gradient;
  &__img{
    position: absolute;
    left: -8em;
    bottom: -4.375em;
    width: 42.5em;
  }
}

.about-counts{
  background-color: $black;
  color: $white;
  &__groups{
    @include grid100gap(8.75em);
  }
}

.about-count{
  display: grid;
  grid-template-columns: 34.5em calc(100% - 37em);
  gap: 2em 2.5em;
  &__img{
    width: 100%;
    min-height: 100%;
    display: block;
    object-fit: cover;
  }
  &__text{
    @include grid100gap(.5em);
  }
  &__items{
    margin-top: 3em;
    display: grid;
    grid-template-columns: 50% 50%;
    width: calc(100% + 1em);
  }
  @media screen and (min-width: 993px){
    &:nth-child(even){
      grid-template-columns: calc(100% - 37em) 34.5em;
      .about-count__col2{
        order: -1;
      }
    }
  }
}

.count-item{
  padding: 0 1em;
  border-left: $border2;
  overflow: hidden;
  &:nth-child(2){
    padding-top: 11.625em;
  }
  &__content{
    min-height: 11.625em;
  }
  &__desc{
    margin-top: .5em;
  }
}

.countries{
  min-height: 52.5em;
  background-image: url(../img/earth.jpg);
  background-size: cover;
  background-position: left bottom;
  background-color: $black;
  color: $white;
  &__text{
    width: 42.25em;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    @include grid100gap(2em);
  }
  &__items{
    display: grid;
    grid-template-columns: repeat(6,auto);
    gap: 2.5em 6em;
    margin: 2em auto 0;
    max-width: 75.6875em;
  }
}

.country-item2{
  display: flex;
  align-items: center;
  padding: 0 .75em;
  background-color: $white-12;
  height: 3em;
  img{
    width: 1.5em;
    margin-right: .5em;
  }
}


@media screen and (max-width: 1500px){
  .tec-doc-provider{
    &__img{
      left: -3.75em;
    }
    .defect-percent__content{
      max-width: 42.5em;
      position: relative;
      z-index: 2;
    }
  }

  .about-count{
    grid-template-columns: 28.75em calc(100% - 31.25em);
    &__text{
      @include grid100gap(.5em);
    }
    @media screen and (min-width: 993px){
      &:nth-child(even){
        grid-template-columns: calc(100% - 31.25em) 28.75em;
      }
    }
  }

  .count-item{
    &:nth-child(2){
      padding-top: 9em;
    }
    &__content{
      min-height: 9em;
    }
  }

  .countries{
    min-height: 41.75em;
    &__items{
      grid-template-columns: repeat(6,auto);
      gap: 2.5em 5.375em;
    }
  }
}


@media screen and (max-width: 992px){
  .tec-doc-provider{
    min-height: unset;
    &__img{
      display: none;
    }
    .defect-percent__content{
      max-width: 100%;
    }
  }

  .about-counts{
    &__groups{
      gap: 6em;
    }
  }

  .about-count{
    grid-template-columns: 100%;
    &__items{
      margin-top: 2em;
    }
  }

  .count-item{
    &:nth-child(2){
      padding-top: 0;
    }
    &__content{
      min-height: unset;
    }
  }

  .countries{
    min-height: 41.25em;
    padding: 3em 0;
    &__text{
      max-width: 100%;
      gap: 2em;
    }
    &__items{
      grid-template-columns: repeat(4,auto);
      gap: 2.5em 2.25em;
      max-width: 37.25em;
    }
  }
}


@media screen and (max-width: 576px){
  .manufacture2{
    &__items{
      grid-template-columns: repeat(2,calc(50% - 1em));
      gap: 2em;
    }
    &__item{
      &:after{
        margin-left: 1em;
      }
    }
    .manufacture__content{
      gap: 1em;
    }
    .m-nav{
      margin-top: 1em;
    }
  }

  .countries{
    min-height: 40em;
    padding: 2.25em 0;
    &__text{
      gap: 1em;
    }
    &__items{
      margin-top: 1.5em;
      grid-template-columns: repeat(2,auto);
      gap: 1em 2em;
      max-width: 18em;
    }
  }
}
