.page-top{
  background-color: #001E56;
  color: $white;
  padding-bottom: 2.75em;
  position: relative;
  .container{
    position: relative;
    z-index: 2;
  }
  .breadcrumbs {
    margin: 0;
    li{
      &:not(:first-child) a{
        &:before {
          background-image: url(../img/arrow-right.svg);
        }
      }
      &:last-child{
        color: $white-64;
      }
    }
  }
  &__img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      transform: scale(1.15);
    }
  }
  &__content{
    padding: 3em 0;
    min-height: 17.75em;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__title{
    padding-left: 2.25em;
    position: relative;
  }
  &__line{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transform-origin: top;
    width: .25em;
    background-color: $lightBlue;
  }
  &__desc{
    margin-top: 2em;
  }
  &__date{
    margin-bottom: 1.5em;
  }
}

.contacts{
  &__top{
    display: grid;
    grid-template-columns: repeat(4,25%);
    min-height: 9.25em;
    background-color: $lightGrey;
  }
  &__info{
    padding: 2em 1.5em;
    background-color: $darkBlue;
    color: $white;
    .office-card__title{
      color: $white-64;
      margin-bottom: 1em;
    }
  }
  .map{
    margin-top: 3em;
    height: 45em;
  }
}

.contact-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5em .75em;
  border-right: $border;
  text-align: center;
  &:last-child{
    border: none;
  }
  &__icon{
    height: 2em;
    margin-bottom: 1em;
  }
  &__content{
    @include grid100gap(.25em);
  }
}


@media screen and (max-width: 1500px){
  .contacts{
    .map{
      height: 38em;
    }
  }
}


@media screen and (max-width: 992px){
  .page-top{
    padding-bottom: 2.5em;
    &__content{
      min-height: 12.375em;
    }
    &__title{
      padding-left: 1.75em;
    }
    &__desc{
      margin-top: 1.5em;
    }
  }

  .contacts{
    &__top{
      grid-template-columns: repeat(3,calc(100% / 3));
    }
    &__info{
      padding: 1em;
      grid-column: 1/-1;
    }
    .map{
      margin-top: 2em;
      height: 23.5em;
    }
  }

  .contact-item{
    padding: 1em;
  }
}


@media screen and (max-width: 576px){
  .page-top{
    padding-bottom: 1.5em;
    &__content{
      min-height: 10.875em;
    }
  }

  .contacts {
    &__top {
      grid-template-columns: 100%;
    }
  }

  .contact-item{
    border-right: none;
    border-bottom: $border;
  }
}
