.product-group{
  &__grid{
    row-gap: 1.5em;
  }
  .m-nav{
    justify-content: center;
  }
}

.page-nav{
  background-color: $lightGrey;
  &__inner{
    display: flex;
    align-items: center;
  }
  &__links{
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(5,20%);
  }
  &__link{
    @include center;
    text-align: center;
    padding: 1.71em 1.14em;
    font-size: .875em;
    line-height: 143%;
    letter-spacing: 0.01em;
    font-weight: 700;
  }
  &__btn{
    margin-left: 2.5em;
    flex-shrink: 0;
    svg{
      width: 1.25em;
      height: 1.25em;
      margin-left: .5em;
    }
  }
}


@media screen and (max-width: 1500px){

}


@media screen and (max-width: 992px){
  .page-nav{
    .container{
      padding: 0;
    }
    &__inner{
      overflow-x: auto;
      padding: 0 1.5em 0 0;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
    &__links{
      display: flex;
    }
    &__link{
      flex-shrink: 0;
      padding: 1.14em;
      white-space: nowrap;
    }
    &__btn{
      margin-left: 0;
    }
  }
}


@media screen and (max-width: 576px){
  .product-group{
    .m-nav{
      margin-top: 1.5em;
    }
  }

  .page-nav{
    &__inner{
      padding: 0 1em 0 0;
    }
    &__link{
      font-size: .8125em;
      line-height: 138%;
      padding: 1.23em;
    }
  }
}
