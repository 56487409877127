.modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $vh;
    background-color: $black;
    opacity: .5;
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    z-index: 80;
    opacity: 0;
    pointer-events: none;
    //transition: all .3s;
    padding: 1em 0;
    text-align: center;
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    @media screen and (min-width: 993px){
        //height: 100vh;
    }
    &:after {
        //content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -4px;
    }
    &__bg{
        //pointer-events: none;
    }
    &__dialog {
        text-align: left;
        vertical-align: middle;
    }
    &._active {
        opacity: 1;
        pointer-events: unset;
        height: 100%;
        overflow-y: auto;
        .modal__bg {
            display: block;
        }
    }
    &__dialog {
        background: $white;
        width: 62.5em;
        max-width: 100%;
        position: relative;
        z-index: 3;
        padding: 1.5em;
        //display: inline-block;
        &-wrapper{
            margin: auto;
            max-width: 100%;
        }
    }
    &._scroll{
        @media screen and (min-width: 993px){
            .modal__dialog{
                //max-height: calc(100 * var(--vh) - 2em);
                //overflow-y: auto;
                //@include scroll;
            }
        }
    }
}

.close-btn {
    width: 1.5em;
    height: 1.5em;
    @include center;
    flex-shrink: 0;
    svg {
        fill: $black-64;
        width: 100%;
        height: 100%;
    }
    &:hover {
        svg {
            fill: $black;
        }
    }
}

.partner-modal {
    .modal__dialog {
        width: 35.25em;
    }
    .partner-form {
        margin-top: 1.5em;
    }
}

.provider-modal {
    .modal__dialog {
        width: 64em;
    }
    .provider-form {
        margin-top: 1.5em;
    }
}

.success-modal {
    .modal__top {
        justify-content: flex-end;
    }
    .modal__dialog {
        width: 23.25em;
    }
    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    &__icon {
        width: 3.5em;
    }
    &__text {
        @include grid100gap(.5em);
        margin-top: 1em;
    }
}

.cookie-modal {
    position: fixed;
    bottom: 0;
    left: 0;
    background: $white;
    z-index: 31;
    padding: 1em 0;
    width: 100%;
    transition: all .3s;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0 -.25em 1.5em 0 rgba(0, 0, 0, 0.16);
    &._active {
        opacity: 1;
        pointer-events: unset;
    }
    &__inner {
        display: grid;
        grid-template-columns: calc(100% - 8.625em) 7.625em;
        gap: 1em;
        align-items: center;
    }
    &__nav {
        @include grid100gap(1em);
    }
    &__btn {
        padding: 0;
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .modal {
        padding: 0;
        &__dialog {
            height: $vh;
            overflow-y: auto;
            overflow-x: hidden;
        }
        &:after {
            display: none;
        }
    }
    .cookie-modal {
        &__inner {
            grid-template-columns: calc(100% - 8.375em) 7.375em;
        }
    }
}

@media screen and (max-width: 576px) {
    .modal {
        &__dialog {
            padding: 1em;
        }
    }
    .cookie-modal {
        &__inner {
            grid-template-columns: 100%;
        }
        &__nav {
            grid-template-columns: repeat(2, calc(50% - .5em));
        }
    }
}
