$font1:'Verano Sans',sans-serif;
$font2:'SF UI Display',sans-serif;
$black: #000;
$white: #fff;
$primary: #06f;
$red: #B10000;
$footerColor: #001233;
$lightBlue: #198de0;
$darkBlue: #003371;
$lightGrey: #f1f4fa;
$black-64: rgba($black, 0.64);
$black-40: rgba($black, 0.4);
$black-12: rgba($black, 0.12);
$white-64: rgba($white, 0.64);
$white-40: rgba($white, 0.4);
$white-12: rgba($white, 0.12);
$gradient: linear-gradient(135.00deg, rgb(0, 51, 113) 0%,rgb(8, 13, 77) 100%);

$border: 1px solid $black-12;
$border2: 1px solid $white-12;

$vh: calc(100 * var(--vh));
