.m-table{
  table{
    width: 100%;
    border-collapse: collapse;
  }
  th{
    font-weight: 700;
    border-bottom: $border;
  }
  th,td{
    padding: .75em .5em;
  }
  &__th{
    display: flex;
    align-items: center;
    color: $black-64;
    .sort-btn{
      margin-left: .25em;
    }
  }
}

.sort-btn{
  width: 1.25em;
  height: 1.25em;
  @include center;
  position: relative;
  svg{
    width: .75em;
    height: .75em;
    fill: $black-64;
    @include centerAbsolute;
  }
  ._view2{
    opacity: 0;
  }
  &._active{
    ._view1{
      opacity: 0;
    }
    ._view2{
      opacity: 1;
    }
  }
  &._reverse{
    ._view2{
      transform: translate(-50%,-50%) rotate(-180deg);
    }
  }
  &:hover{
    svg{
      fill: $lightBlue;
    }
  }
}

.usability-table{
  margin-top: 1.5em;
  th,td{
    width: 7em;
    &:nth-child(1){
      width: 20.625em;
    }
    &:nth-child(2){
      width: 10em;
    }
  }
  td{
    transition: all .3s;
  }
  tbody{
    tr{
      &:hover{
        cursor: pointer;
        td{
          background-color: $lightGrey;
        }
      }
    }
  }
  &__date{
    white-space: nowrap;
  }
}

.usability-car{
  margin-top: 1.5em;
  display: grid;
  grid-template-columns: 15.75em calc(100% - 17em);
  gap: 1em 1.25em;
  &__img{
    img{
      width: 100%;
      display: block;
    }
  }
  &__items{
    display: flex;
    flex-wrap: wrap;
  }
  &__item{
    margin: 0 .57em .57em 0;
  }
  &__nav{
    display: flex;
    margin-top: .5em;
  }
}

.usability-info{
  margin-top: 1.5em;
  display: grid;
  grid-template-columns: repeat(2,calc(50% - .625em));
  gap: 1.25em;
  .main-info{
    padding: 1em;
    margin: 0;
    border: $border;
    &__items{
      gap: 1em;
    }
  }
}


@media screen and (max-width: 1500px){

}


@media screen and (max-width: 992px){
  .usability-table{
    th,td{
      width: auto;
      &:nth-child(1){
        width: 17.625em;
      }
      &:nth-child(2){
        width: 7.25em;
      }
      &:last-child{
        width: 6em;
      }
    }
  }

  .usability-car{
    &__item,
    &__date{
      font-size: .8125em;
      line-height: 138%;
    }
  }

  .usability-info{
    grid-template-columns: 100%;
    .main-info{
      &__items{
        gap: .75em;
        margin-top: 1em;
      }
      &__item{
        @media screen and (min-width: 577px){
          grid-template-columns: 11.25em calc(100% - 11.5em);
        }
      }
    }
  }

}


@media screen and (max-width: 576px){
  .m-table{
    th,td{
      padding: .5em .25em;
    }
  }

  .usability-table{
    padding: 0 1em .5em;
    margin-left: -1em;
    width: calc(100% + 2em);
    overflow-x: auto;
    @include scroll;
    table{
      min-width: 30em;
    }
    th,td{
      width: auto;
      &:nth-child(1){
        width: 10.25em;
      }
      &:nth-child(2){
        width: 4.5em;
      }
      &:last-child{
        width: auto;
      }
    }
    td{
      vertical-align: top;
    }
    .subtitle2,
    .body-text2{
      font-size: .6875em;
      line-height: 145%;
      letter-spacing: 0.04em;
    }
  }

  .usability-car{
    grid-template-columns: 100%;
    &__img{
      height: 6.75em;
      @include center;
      picture,
      img{
        width: auto;
        height: 100%;
      }
    }
    &__link{
      width: 100%;
    }
  }
}
