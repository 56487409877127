.quick-return{
  &__grid{
    display: grid;
    grid-template-columns: 49.25em calc(100% - 51.75em);
    gap: 2em 2.5em;
    align-items: center;
  }
  &__img{
    img{
      width: 100%;
      display: block;
    }
  }
}

.guarantee-steps{
  background-color: $black;
  color: $white;
  &__desc{
    margin-top: .5em;
  }
  &__items{
    margin: 3em 0 0 -1em;
    width: calc(100% + 2em);
    display: grid;
    grid-template-columns: repeat(4,25%);
  }
  &__col{
    padding: 0 1em;
    border-right: $border2;
    overflow: hidden;
    &:nth-child(2){
      padding-top: 14.75em;
    }
    &:nth-child(3){
      padding-top: 29.5em;
    }
    &:nth-child(4){
      padding-top: 45.75em;
    }
  }
}

.step-item{
  .advantage-item2__inner{
    padding: .875em;
  }
  &__num{
    position: relative;
    z-index: 3;
    margin-bottom: .75em;
  }
}

.guarantee-info{
  padding: 4.375em 0;
  background-image: url(../img/guarantee-info.webp);
  background-size: cover;
  background-position: center;
  background-color: $footerColor;
  color: $white;
  &__text{
    margin-top: 2em;
    padding: 1em;
    position: relative;
    &:before,
    &:after{
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: .25em;
      background-color: $lightBlue;
    }
    &:before{
      left: 0;
    }
    &:after{
      left: calc(50% + .5em);
    }
    ul{
      column-count: 2;
      column-gap: 3em;
    }
  }
}


@media screen and (max-width: 1500px){
  .quick-return{
    &__grid{
      grid-template-columns: 41.25em calc(100% - 43.75em);
    }
  }
}


@media screen and (max-width: 992px){
  .quick-return{
    &__grid{
      grid-template-columns: 100%;
    }
    &__col2{
      order: -1;
    }
  }

  .guarantee-steps{
    &__items{
      margin: 2em 0 0;
      width: 100%;
      grid-template-columns: repeat(2,calc(50% - .5em));
      gap: 1em;
    }
    &__col{
      padding: 0 !important;
      border-right: none;
    }
  }

  .step-item{
    height: 100%;
    transform: unset !important;
  }

  .guarantee-info{
    padding: 3em 0;
  }
}


@media screen and (max-width: 576px){
  .guarantee-steps{
    &__items{
      grid-template-columns: 100%;
    }
  }

  .guarantee-info{
    padding: 2.25em 0;
    &__text{
      margin-top: 1.5em;
      &:after{
        display: none;
      }
      ul{
        column-count: 1;
        padding: 0;
      }
    }
  }
}
