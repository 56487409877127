.page-top3{
  &__title{
    text-align: center;
    margin: 0 auto;
    width: 40em;
    max-width: 100%;
  }
}

.contacts2{
  padding: 3em 0 3.75em;
  &__inner{
    width: 82.5em;
    max-width: 100%;
    margin: 0 auto;
  }
  &__title{
    margin-top: 2em;
  }
  &__info{
    margin-top: 2em;
    display: grid;
    grid-template-columns: repeat(2,calc(50% - 1.25em));
    gap: 0 2.5em;
  }
  &__text{
    margin-top: 2em;
  }
  &__site{
    display: flex;
    margin-top: 1em;
    &-link{
      display: flex;
      align-items: center;
      img{
        width: 1.5em;
        margin-right: 1em;
      }
    }
  }
  &__text2{
    margin-top: 1em;
  }
}

.contact-item2{
  display: flex;
  justify-content: space-between;
  padding: .5em 0;
  &__desc{
    text-align: right;
    padding-left: 1em;
  }
}

.conf{
  padding: 3em 0 3.75em;
  &__top{
    @include grid100gap(1em);
  }
  &__text,
  .contacts2__info{
    margin-top: 1.5em;
  }
  &__items{
    margin-top: 2em;
    @include grid100gap(1em);
  }
}

.qa-card{
  &._active{
    .qa-card__show{
      background-color: $primary;
      color: $white;
    }
    .qa-card__btn svg{
      fill: $white;
      transform: rotate(90deg);
    }
  }
  &__show{
    padding: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $lightGrey;
    cursor: pointer;
    transition: all .3s;
  }
  &__hidden{
    display: none;
  }
  &__btn{
    background-color: transparent;
    margin-left: 1em;
    svg{
      width: 1.5em;
      height: 1.5em;
      fill: $black;
    }
  }
  &__hidden{
    padding: 1em;
  }
  .body-text{
    ._mb0{
      margin-bottom: 0;
    }
    ul{
      padding-left: 0;
      margin-bottom: 1em;
      &:last-child{
        margin-bottom: 0;
      }
      li{
        padding-left: .875em;
        margin-bottom: 0;
        &:before{
          left: .125em;
        }
      }
    }
    hr{
      height: .5em;
    }
  }
}


@media screen and (max-width: 1500px){

}


@media screen and (max-width: 992px){
  .contacts2{
    padding: 2em 0 3em;
    &__title{
      margin-top: 1.5em;
    }
    &__info{
      margin-top: 1.5em;
      grid-template-columns: 100%;
    }
    &__text{
      margin-top: 1.5em;
    }
  }

  .conf{
    padding: 2em 0 3em;
  }
}


@media screen and (max-width: 576px){

}
