.where-buy2{
  padding: 3.75em 0;
  position: relative;
  background: $gradient;
  color: $white;
  .container{
    position: relative;
    z-index: 2;
  }
  &__text{
    max-width: 49em;
    @include grid100gap(1em);
  }
  &__nav{
    display: flex;
    margin-top: 2em;
  }
  &__img{
    ._view2{
      display: none;
    }
  }
}

._full-img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}

.become-partner{
  background-color: $white;
  &__grid{
    display: grid;
    grid-template-columns: calc(100% - 37em) 34.5em;
    gap: 2.5em;
    align-items: center;
  }
  &__img{
    width: 100%;
    display: block;
  }
}

.partner-form{
  margin-top: 2em;
  &__fields{
    @include grid100gap(1.5em);
  }
  &__hint{
    margin-top: 1em;
    color: $red;
  }
  .m-check{
    margin-top: 1.5em;
    label{
      padding-left: 2.25em;
    }
  }
  &__nav{
    display: flex;
    margin-top: 1.5em;
  }
}

.tel-input{
  input{
    padding-left: 6em !important;
  }
  .iti{
    width: 100%;
    &__selected{
      &-flag{
        padding: 0 1em;
        border-right: $border;
      }
    }
  }
}


@media screen and (max-width: 1500px){
  .become-partner{
    &__grid{
      grid-template-columns: calc(100% - 31.25em) 28.75em;
    }
  }
}


@media screen and (max-width: 992px){
  .where-buy2{
    padding: 3em 0;
    min-height: 30em;
    &__img{
      ._view1{
        display: none;
      }
      ._view2{
        display: block;
      }
    }
  }

  .become-partner{
    &__grid{
      grid-template-columns: 100%;
    }
    &__img{
      display: none;
    }
  }

  .partner-form{
    margin-top: 1.5em;
    &__fields{
      gap: 1em;
    }
  }
}


@media screen and (max-width: 576px){
  .where-buy2{
    padding: 2em 0;
    &__btn{
      width: 100%;
    }
  }

  .partner-form{
    &__btn{
      width: 100%;
    }
  }
}
