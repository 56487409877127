.logistic-system{
  background-image: url(../img/logistic-system.jpg);
  background-size: cover;
  background-position: center;
  background-color: $black;
  color: $white;
  text-align: center;
  &__img{
    width: 100%;
    display: block;
    margin-top: 3em;
  }
}

.become-provider2{
  background-color: $white;
}

.provider-form{
  margin-top: 2em;
  &__grid{
    display: grid;
    grid-template-columns: repeat(2,calc(50% - 1.25em));
    gap: 1em 2.5em;
  }
  &__fields{
    @include grid100gap(1.5em);
  }
  &__btn{
    margin-left: 1em;
    flex-shrink: 0;
  }
}

.file-input{
  border: 1px dashed $black-40;
  padding: 1.25em 1em 1em;
  min-height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all .3s;
  &:hover{
    background-color: $lightGrey;
    border-color: $lightBlue;
    .file-input__hint{
      opacity: 1;
      pointer-events: unset;
    }
    input{
      z-index: 4;
    }
  }
  &__hint{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1em;
    @include center;
    background-color: $lightGrey;
    color: $black-64;
    transition: all .3s;
    z-index: 3;
    opacity: 0;
    pointer-events: none;
  }
  &__wrap{
    margin-top: .75em;
  }
  .file-btn{
    margin-right: .5em;
  }
  &__uploaded{
    margin-top: .25em;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    z-index: 4;
    .file-item{
      margin: .25em .25em 0 0;
    }
  }
  &._uploaded{
    .file-input__hint{
      display: none;
    }
  }
}

.file-item{
  width: 3em;
  position: relative;
  //background: rgba($black, 0.2);
  &__img{
    height: 3em;
    @include center;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__remove{
    position: absolute;
    width: 1.25em;
    height: 1.25em;
    top: 0;
    right: 0;
    z-index: 2;
    img{
      width: .75em;
    }
  }
}

.file-btn{
  @include center;
  transition: all .3s;
  padding: 0 1em;
  background-color: $black-12;
  height: 2.5em;
  color: $black-64;
  font-weight: 700;
  svg{
    width: 1em;
    height: 1em;
    margin-right: .5em;
  }
  path{
    fill: $black-64;;
  }
  span{
    font-size: .75em;
    line-height: 133%;
    letter-spacing: 0.04em;
  }
  &:active,
  &:hover{
    cursor: pointer;
    color: $white;
    background-color: $lightBlue;
    path{
      fill: $white;;
    }
  }
  &:active{
    background-color: $primary;
  }
}


@media screen and (max-width: 1500px){

}


@media screen and (max-width: 992px){
  .logistic-system{
    &__img{
      margin-top: 2em;
    }
  }

  .provider-form{
    &__grid{
      grid-template-columns: 100%;
    }
    &__fields{
      gap: 1em;
    }
  }
}


@media screen and (max-width: 576px){
  .logistic-system{
    padding: 4.5em 0;
  }

  .become-provider2{
    padding: 4.5em 0;
  }

  .provider-form{
    &__bottom{
      display: block;
    }
    &__btn{
      margin: 1.5em 0 0;
      width: 100%;
    }
  }
}
