.breadcrumbs {
    padding: 5em 0 .75em;
    margin-bottom: 1.5em;
    li{
        position: relative;
        display: inline;
        min-height: 1.25em;
        &:not(:first-child) {
            margin-left: .75em;
            a{
                &:before {
                    content: '/';
                    color: transparent;
                    position: relative;
                    z-index: 1;
                    top: .125em;
                    right: .625em;
                    margin-right: 0;
                    width: .71em;
                    height: 1.07em;
                    background-image: url(../img/arrow-right-black.svg);
                    @include bgImgSize(100%);
                    background-position: center;
                    display: inline-flex;
                }
            }
        }
        &:last-child{
            pointer-events: none;
            color: $black-64;
        }
    }
    a{
        font-size: .875em;
        line-height: 143%;
        letter-spacing: 0.01em;
    }
}



@media screen and (max-width: 992px){
    .breadcrumbs{
        padding: 4.5em 0 .75em;
        a{
            font-size: .8125em;
            line-height: 138%;
        }
    }
}


@media screen and (max-width: 576px){
    .breadcrumbs{
        margin-bottom: 1em;
    }
}
