.fg,
.file-input__group,
.country-select{
  label{
    display: block;
    font-size: .875em;
    line-height: 143%;
    letter-spacing: 0.01em;
    margin-bottom: .57em;
    font-weight: 700;
    span{
      color: #B10000;
    }
  }
}

.fg {
  position: relative;
  margin: 0;
  .nice-select,
  input,
  textarea{
    border-radius: 0;
    border: 1px solid $lightGrey;
    padding: 0 1.14em;
    height: 3.6875em;
    width: 100%;
    font-size: .875em;
    line-height: 143%;
    letter-spacing: 0.01em;
    background-color: $lightGrey;
    color: $black;
    &::placeholder {
      color: $black-40;
    }
    &:hover{
      border-color: $lightBlue;
    }
    &:focus {
      border-color: $lightBlue;
      background-color: transparent;
    }
  }
  textarea{
    padding-top: 1em;
    padding-bottom: 1em;
    height: 6.86em;
    resize: none;
  }
  &--comment{
    textarea{
      height: 17.625em;
    }
  }
  &__field{
    position: relative;
  }
  &._error{
    .fg__error-text{
      display: block;
    }
  }
  &__error-text{
    margin-top: 1em;
    color: $red;
    display: none;
  }
}

.pass-toggle{
  position: absolute;
  bottom: 1em;
  right: 1.25em;
  width: 1.25em;
  height: 1.6875em;
  @include center;
  svg{
    width: 100%;
    height: auto;
  }
  &._active{
    g{
      opacity: 1;
    }
  }
}

.file-input,
.m-radio,
.m-check{
  position: relative;
  input{
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    cursor: pointer;
  }
}

.m-check,
.m-radio{
  label{
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: .25em 0 .25em 1.5em;
    min-height: 1.75em;
    span{
      font-size: .875em;
      line-height: 143%;
      letter-spacing: 0.01em;
    }
    &:before,
    &:after{
      pointer-events: none;
    }
    &:before{
      content: '';
      position: absolute;
      z-index: 1;
      left: .125em;
      top: 50%;
      transform: translateY(-50%);
      border: 1px solid $black-40;
      width: 1em;
      height: 1em;
      @include bgImgSize(0);
      transition: all .0s;
    }
    a{
      text-decoration: underline;
      position: relative;
      z-index: 2;
    }
  }
  input:hover~label{
    &:before{
      border-color: $primary;
    }
  }
}

.m-check {
  input {
    &:checked+label {
      &::before {
        border: none;
        background-size: 100% auto;
      }
    }
  }
  label {
    &:before {
      background-image: url(../img/check.svg);
    }
  }
}

.m-radio{
  input:checked~label{
    &:before{
      border: none;
      background-size: 100% auto;
    }
  }
  label{
    &:before{
      border-radius: 50%;
      background-image: url(../img/radio.svg);
    }
    span{
      display: block;
      line-height: 157%;
      color: #5B626C;
      margin-top: .125em;
    }
  }
}

.cartcalc{
  max-width: 100%;
  width: 7.5625rem;
  display: grid;
  grid-template-columns: 2.1875em calc(100% - 2.1875em * 2) 2.1875em;
  border: 1px solid #E9EDF2;
  height: 3.125em;
  button,
  input{
    height: 100%;
    width: 100%;
    min-height: 100%;
  }
  input{
    border: none;
    border-left: 1px solid #E9EDF2;
    border-right: 1px solid #E9EDF2;
    color: $black;
    font-weight: 500;
    font-size: 1em;
    text-align: center;
  }
  svg{
    width: .5em;
    height: auto;
  }
  button{
    @include center;
    &:hover{
      background-color: #E6E6EC;
    }
  }
}


@media screen and (max-width: 992px){
  .fg{
    &--comment{
      textarea{
        height: 9.15em;
      }
    }
  }
}


@media screen and (max-width: 576px){

}
