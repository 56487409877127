.advantages2{
  background-color: $black;
  color: $white;
  &__grid{
    row-gap: 2.5em;
  }
}

.advantage-item2{
  padding: .125em;
  border: none;
  position: relative;
  &:after,
  &:before{
    content: '';
    position: absolute;
    background-image: linear-gradient(143deg,#0c0c0d,#636369 21%,#272729 37%,#636369 60%,#37373a 85%,#0c0c0d);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: all .3s;
  }
  &:after{
    background-image: linear-gradient(143deg,$primary,#0c0c0d 21%,#272729 41%,$primary 60%,$footerColor 78%,#0c0c0d);
    opacity: 0;
    z-index: 2;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(148deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
  &__inner{
    position: relative;
    z-index: 3;
    padding: 1.375em;
    height: 100%;
    background-image: linear-gradient(143deg,#0c0c0d 23%,#222224);
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(42deg,#000,rgba($primary,.1));
      transition: all .3s;
      opacity: 0;
    }
  }
  &__icon,
  &__text{
    position: relative;
    z-index: 2;
  }
  &:hover{
    &:after{
      opacity: 1;
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    }
    .advantage-item2__inner:before{
      opacity: 1;
    }
    &:before{
      //opacity: 0;
    }
  }
}

.assortment{
  background: $gradient;
  color: $white;
  &__inner{
    padding: 4.375em 0;
    position: relative;
  }
  &__content{
    max-width: 49.25em;
  }
  &__text{
    @include grid100gap(1em);
  }
  &__nav{
    display: flex;
    margin-top: 2em;
  }
  &__img{
    position: absolute;
    right: -6.25em;
    bottom: 0;
    width: 41.25em;
    @media screen and (max-width: 1700px){
      right: -3.75em;
    }
  }
}

.guarantee{
  &__grid{
    display: grid;
    grid-template-columns: 34.5em calc(100% - 37em);
    gap: 2em 2.5em;
    align-items: center;
  }
  &__img{
    img{
      width: 100%;
      display: block;
    }
  }
  &__duration{
    margin-top: 2em;
    padding: 1.5em 1.5em 1.5em 1.25em;
    background-color: $lightGrey;
    border-left: .25em solid $primary;
  }
}

.m-nav{
  display: flex;
  margin-top: 2em;
}

.article{
  &__nav{
    margin-top: 3em;
  }
  &__link{
    svg{
      width: 1.5em;
      height: 1.5em;
      margin: 0 .5em;
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
}


@media screen and (max-width: 1500px){
  .assortment{
    &__content{
      max-width: 41.25em;
    }
    &__img{
      width: 39em;
    }
  }

  .guarantee{
    &__grid{
      grid-template-columns: 28.75em calc(100% - 31.25em);
    }
  }
}


@media screen and (max-width: 992px){
  .advantages2{
    &__grid{
      row-gap: 1em;
    }
  }

  .advantage-item2{
    &__inner{
      padding: .875em;
    }
  }

  .assortment{
    &__inner{
      padding: 3em 0;
    }
    &__content{
      max-width: 100%;
    }
    &__img{
      display: none;
    }
  }

  .guarantee{
    &__grid{
      grid-template-columns: 100%;
    }
    &__duration{
      margin-top: 1.5em;
    }
  }

  .article{
    &__nav{
      margin-top: 2em;
    }
  }
}


@media screen and (max-width: 576px){
  .assortment{
    &__inner{
      padding: 2.25em 0;
    }
    &__btn{
      width: 100%;
    }
  }

  .guarantee{
    &__duration{
      margin-top: 1em;
      padding: 1em 1em 1em .75em;
    }
  }

  .m-nav{
    &__btn{
      width: 100%;
    }
  }
}
