@import 'vars';
@import 'components/_text.scss';
@import 'components/_buttons.scss';
@import "components/_inputs.scss";
@import "components/_pagination.scss";
@import "components/_tabs.scss";
@import "components/_grid.scss";
@import "components/_filter-slider.scss";
.js-scroll-scale-img{
  overflow: hidden;
  img{
    transform: scale(1.3);
  }
}

._scale-img{
  overflow: hidden;
  &:hover{
    img{
      transform: scale(1.05);
    }
  }
  img{
    transition: all .6s;
  }
}

.m-backdrop{
  position: relative;
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    pointer-events: none;
    border-radius: inherit;
  }
}

.animated-opacity{
  &.animated{
    opacity: 0;
    transform: translateY(80px);
    transition: all 1.6s;
    &--active{
      transform: translateY(0);
      opacity: 1;
    }
  }
}

._mt32{
  margin-top: 2em;
}

._mt24{
  margin-top: 1.5em;
}

._mt16{
  margin-top: 1em;
}


@media screen and (max-width: 992px){
  ._mt24-tablet{
    margin-top: 1.5em;
  }

  ._mt16-tablet{
    margin-top: 1em;
  }
}


@media screen and (max-width: 576px){
  ._mt16-mob{
    margin-top: 1em;
  }
}
