.experience{
  position: relative;
  background-color: $black;
  color: $white;
  &__img{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 7.5em);
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
    &:after{
      background: linear-gradient(180.00deg, rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0) 44.064%),linear-gradient(180.00deg, rgba(0, 0, 0, 0) 54%,rgba(0, 0, 0, 0.2) 100%);
    }
  }
  &__inner{
    position: relative;
    z-index: 2;
    min-height: 52.5em;
    padding: 3.75em 0;
    display: flex;
    flex-direction: column;
  }
  &__top{
    text-align: center;
    @include grid100gap(.5em);
  }
  &__nav{
    display: flex;
    justify-content: center;
    margin-top: 1em;
  }
  &__bottom{
    margin: auto auto 0;
    width: 78.75em;
    max-width: 100%;
  }
  &__items{
    display: grid;
    grid-template-columns: repeat(4,calc(25% - 1.5em));
    gap: 1em 2em;
  }
  &__item{
    text-align: center;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 1px;
      background-color: $white-12;
      margin-left: 1em;
    }
    &:last-child{
      &:after{
        display: none;
      }
    }
    &-text{
      margin-top: .5em;
    }
  }
}

._z2{
  z-index: 2;
  position: relative;
}

.swiper-pagination{
  &-bullet{
    width: 2em;
    height: .25em;
    border-radius: 0;
    opacity: 1;
    background-color: $white-40;
    margin: 0 .5em !important;
    transition: all .3s;
    &:hover,
    &-active{
      background-color: $primary;
    }
  }
}

._hidden-x{
  overflow-x: hidden;
}

.main-slider{
  position: relative;
  .slider__wrap{
    overflow: hidden;
    position: relative;
    padding-bottom: $vh;
    margin-bottom: calc(100 * var(--vh) * -1);
  }
  .swiper{
    &-wrapper{
      position: static;
    }
    &-pagination{
      bottom: 1.875em;
    }
  }
}

.main-slide{
  padding-top: 4.25em;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  color: $white;
  position: static;
  &:after{
    background: linear-gradient(90.00deg, rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.4) 45.69%,rgba(0, 0, 0, 0) 100%);
    z-index: 2;
  }
  &__media{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;
    video,
    img{
      position: relative;
      top: 0;
      width: 100%;
      height: calc(100 * var(--vh) + 1em);
      margin-top: -1em;
      object-fit: cover;
    }
  }
  &__inner{
    height: calc(100 * var(--vh) - 4.25em);
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
  }
  &__content{
    max-width: 38em;
  }
  &__text{
    margin-top: 1em;
  }
  &__logo{
    width: 11.6875em;
    display: block;
    margin-top: 2em;
  }
  &__nav{
    margin-top: 2em;
    display: flex;
  }
}

.m-video{
  &::-webkit-media-controls-panel {
    display: none!important;
    -webkit-appearance: none;
  }
  &::-webkit-media-controls-play-button {
    display: none!important;
    -webkit-appearance: none;
  }
  &::-webkit-media-controls-start-playback-button {
    display: none!important;
    -webkit-appearance: none;
  }
}


@media screen and (max-width: 1500px){
  .experience{
    &__img{
      width: 100%;
    }
    &__inner{
      min-height: 41.75em;
    }
  }
}


@media screen and (max-width: 992px){
  .experience{
    &__inner{
      min-height: 45em;
      padding: 3em 0;
    }
  }

  .main-slider{
    overflow: hidden;
  }

  .main-slide{
    padding-top: 3.75em;
    &__inner{
      align-items: unset;
      padding: 6em 0;
      height: calc(100 * var(--vh) - 3.75em);
    }
    &__content{
      max-width: 33.125em;
    }
  }
}


@media screen and (max-width: 576px){
  .experience{
    &__inner{
      min-height: 36.25em;
      padding: 2.25em 0;
    }
    &__link{
      width: 100%;
    }
    &__items{
      grid-template-columns: repeat(2,calc(50% - 1em));
    }
    &__item{
      &:nth-child(even):after{
        display: none;
      }
      .number2{
        font-size: 1.75em;
      }
      &-text{
        font-size: .8125em;
        line-height: 138%;
        margin-top: .61em;
      }
    }
  }

  .main-slider{
    .swiper-pagination{
      display: none;
    }
  }

  .main-slide{
    &__inner{
      padding: 3.75em 0;
    }
    &__logo{
      margin-top: 1.5em;
      width: 9.5em;
    }
    &__nav{
      margin-top: 1.5em;
    }
    &__link{
      width: 100%;
    }
  }
}
