.body-text{
  ul{
    line-height: inherit;
  }
  li{
    line-height: inherit;
    margin-bottom: .5em;
  }
  table,
  .qa-card,
  p{
    line-height: inherit;
    margin-bottom: 1em;
    &:last-child{
      margin-bottom: 0;
    }
  }
  hr{
    display: block;
    height: 2em;
    opacity: 0;
    margin: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    margin-bottom: .67em;
  }
  img{
    display: block;
    width: 100%;
    margin-bottom: 1.5em;
    &:last-child{
      margin-bottom: 0;
    }
  }
  ul{
    gap: .19em;
    padding-left: .6em;
    li{
      padding-left: 1.5em;
      position: relative;
      &:before{
        content: '•';
        position: absolute;
        top: 0;
        left: .5625em;
        background-image: unset;
        width: unset;
        height: unset;
      }
    }
  }
  ol{
    padding-left: .6em;
    counter-reset: section;
    li{
      counter-increment: section;
      padding-left: 1.5em;
      position: relative;
      &:before {
        content: counter(section) '. ';
        position: absolute;
        left: .5625em;
        top: 0;
        width: auto;
        height: auto;
        background-color: transparent;
        font-variant-numeric: tabular-nums;
        -webkit-font-feature-settings: "tnum";
        font-feature-settings: "tnum";
      }
    }
  }
  table{
    width: 100%;
    border-collapse: collapse;
  }
  th{
    text-align: left;
    font-weight: 700;
    border-bottom: $border;
    color: $black-64;
  }
  th,td{
    padding: .75em .5em;
  }
}


@media screen and (max-width: 1500px){

}


@media screen and (max-width: 992px){
  .body-text{
    hr{
      height: 1em;
    }
  }
}


@media screen and (max-width: 576px){
  .body-text{
    table{
      font-size: .75em;
    }
  }
}
