.product-menu{
  padding: 1.5em 0;
  color: $black;
  &__tabs{
    display: grid;
    grid-template-columns: 50% 50%;
  }
  &__tab{
    height: 3.5em;
    @include center;
    color: $black-40;
    font-weight: 700;
    border-bottom: 2px solid $black-12;
    transition: all .3s;
    &:hover{
      cursor: pointer;
      color: $primary;
      svg{
        fill: $primary;
      }
    }
    &._active{
      color: $primary;
      border-color: $primary;
      svg{
        fill: $primary;
      }
    }
    svg{
      width: 1.5em;
      height: 1.5em;
      fill: $black-40;
      margin-right: 1em;
    }
    span{
      font-size: .875em;
      line-height: 143%;
      letter-spacing: 0.01em;
    }
  }
  &__items{
    margin-top: 2em;
    display: grid;
    grid-template-columns: repeat(6,calc(100% / 6 - 5em / 6));
    gap: 1.5em 1em;
  }
  &__nav{
    margin-top: 2em;
    padding-top: 1em;
    border-top: $border;
    display: flex;
    justify-content: flex-end;
  }
  &__link{
    svg{
      width: 1.5em;
      height: 1.5em;
      margin-left: .5em;
      fill: $primary;
    }
    &:active,
    &:hover{
      svg{
        fill: $white;
      }
    }
  }
}

.product-card{
  display: block;
  text-align: center;
  &:hover{
    color: $black;
  }
  &__img{
    @include imgCover(64.8%);
    margin-bottom: .75em;
  }
}

.lang{
  margin-right: 1em;
  position: relative;
  &:hover{
    @media screen and (min-width: 993px){
      .lang__hidden{
        opacity: 1;
        pointer-events: unset;
      }
    }
  }
  &__show{
    cursor: pointer;
    font-weight: 600;
    padding: 0 1em;
    position: relative;
    z-index: 3;
    img{
      width: 1.5em;
      margin-right: .5em;
    }
  }
  &__hidden{
    color: $black;
    transition: all .3s;
    opacity: 0;
    pointer-events: none;
    padding-top: 3.25em;
    position: absolute;
    top: 0;
    margin-top: -1px;
    width: 100%;
    background-color: $white;
    z-index: 2;
    box-shadow: 0 .25em .25em rgba(0, 0, 0, 0.16);
  }
  &__link{
    height: 2.25em;
    @include center;
    &:hover{
      color: $black;
      background-color: $lightGrey;
    }
  }
}


@media screen and (max-width: 1500px){

}


@media screen and (max-width: 992px){
  .product-menu{
    padding-top: 0;
    &__items{
      grid-template-columns: repeat(4,calc(25% - 3em / 4));
      gap: 1em;
      margin-top: 1.5em;
    }
    .tab-content{
      padding: 0 1.5em;
    }
    &__nav{
      margin-top: 1.5em;
    }
  }

  .header-action{
    &__btn{
      display: none;
    }
  }

  .lang{
    margin-right: 0;
    &._open{
      .lang__hidden{
        opacity: 1;
        pointer-events: unset;
      }
    }
    &__show{
      img{
        width: 1.25em;
      }
    }
    .body-text2{
      font-size: .8125em;
    }
  }

  .burger{
    width: 3.5em;
    height: 3.75em;
    @include center;
    margin-right: -1.5em;
    position: relative;
    svg{
      width: 1.5em;
      height: 1.5em;
      fill: $black;
      @include centerAbsolute;
    }
    ._view2{
      opacity: 0;
    }
  }
}


@media screen and (max-width: 576px){
  .product-menu{
    &__tabs{
      grid-template-columns: 100%;
    }
    &__items{
      grid-template-columns: repeat(2,calc(50% - .5em));
    }
    .tab-content{
      padding: 0 1em;
    }
    &__link{
      width: 100%;
    }
  }

  .burger{
    margin-right: -1em;
  }
}
