@import "../vars";
.m-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-out .225s;
    padding: 0 1.5em;
    height: 3.25em;
    font-weight: 700;
    &:focus {
        box-shadow: none;
    }
    span{
        font-size: .875em;
        letter-spacing: 0.01em;
    }
}

.m-btn-blue-outline,
.m-btn-blue{
    &:hover{
        color: $white;
        background-color: $lightBlue;
        border-color: $lightBlue;
        svg{
            fill: $white;
        }
    }
    &:active{
        color: $white;
        background-color: $primary;
        border-color: $primary;
        svg{
            fill: $white;
        }
    }
}

.m-btn-blue{
    color: $white;
    background-color: $primary;
}

.m-btn-blue-outline{
    color: $primary;
    border: 1px solid $primary;
    svg{
        fill: $primary;
    }
}
