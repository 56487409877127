.advantages-top{
  margin-bottom: 3.5em;
  &._mb0{
    @media screen and (min-width: 993px){
      margin-bottom: 0;
    }
  }
  &__inner{
    position: relative;
  }
  .page-top__content{
    min-height: 27.75em;
    max-width: 41.875em;
  }
  .page-top__img{
    right: 0;
    left: unset;
    width: 41.875em;
    height: 35.25em;
  }
}

.advantages{
  background-color: $white;
  &__top{
    margin-bottom: 3em;
  }
  &__grid{
    grid-auto-flow: dense;
  }
  &__img{
    position: static;
    grid-column: 1/3;
    grid-row: 2/4;
  }
  @media screen and (min-width: 993px){
    &--partners{
      .advantages__img{
        grid-row: 2/6;
      }
      .advantage-item{
        &:nth-child(4)~.advantage-item{
          display: flex;
          align-items: flex-start;
          .advantage-item__icon{
            margin: 0 1em 0 0;
          }
        }
      }
    }
  }
}

.advantage-item2,
.advantage-item{
  padding: 1em;
  border-left: $border;
  &__icon{
    height: 4.5em;
    margin-bottom: 1em;
  }
}

.manufacture{
  background-color: $black;
  color: $white;
  &__inner{
    background-image: url(../img/manufacture-bg.jpg);
    background-size: cover;
    background-position: center;
    padding: 18.375em 0;
    &:after{
      background: linear-gradient(91.15deg, rgba(0, 0, 0, 0.7) 10.523%,rgba(0, 0, 0, 0) 71.427%);
    }
  }
  .container{
    position: relative;
    z-index: 2;
  }
  &__content{
    @include grid100gap(2em);
    max-width: 34.875em;
  }
}


@media screen and (max-width: 1500px){
  .advantages-top{
    .page-top__content{
      max-width: 35em;
    }
    .page-top__img{
      width: 35em;
    }
  }

  .manufacture{
    &__inner{
      padding: 13em 0;
    }
  }
}


@media screen and (max-width: 992px){
  .advantages-top{
    margin-bottom: 15.875em;
    padding-bottom: 0;
    .page-top__content{
      max-width: unset;
      padding: 3em 0 2em;
      min-height: unset;
    }
    .page-top__img{
      width: 100%;
      height: auto;
      position: static;
      margin-bottom: -15.875em;
    }
  }

  .advantages{
    &__top{
      margin-bottom: 2em;
    }
    &__img{
      grid-column: 1/-1;
      grid-row: unset;
    }
  }

  .advantage-item2,
  .advantage-item{
    &__icon{
      height: 3.5em;
    }
  }

  .manufacture{
    &__inner{
      padding: 6em 0;
      min-height: 45em;
    }
    &__content{
      gap: 1.5em;
      max-width: 100%;
    }
  }
}


@media screen and (max-width: 576px){
  .advantages-top{
    margin-bottom: 4.625em;
    .page-top__content{
      padding-top: 2em;
    }
    .page-top__img{
      margin-bottom: -4.625em;
    }
  }

  .manufacture{
    &__inner{
      padding: 4.5em 0;
      min-height: 36.25em;
    }
  }
}
