.body-text h1,
.h1{
  font-weight: 800;
  font-size: 3.25em;
  line-height: 119%;
}

.body-text h2,
.h2{
  font-weight: 800;
  font-size: 2.75em;
  line-height: 118%;
}

.body-text h3,
.h3{
  font-weight: 800;
  font-size: 2.25em;
  line-height: 122%;
}

.body-text h4,
.h4{
  font-weight: 800;
  font-size: 1.5em;
  line-height: 133%;
}

.body-text h5,
.body-text h6,
.h5{
  font-weight: 800;
  font-size: 1.125em;
  line-height: 133%;
}

.number1{
  font-weight: 800;
  font-size: 4.5em;
  line-height: 119%;
  letter-spacing: -0.01em;
}

.number2{
  font-weight: 800;
  font-size: 4em;
  line-height: 112%;
  letter-spacing: -0.01em;
}

.text20{
  font-size: 1.25em;
  line-height: 120%;
}

.text19{
  font-size: 1.1875em;
  line-height: 120%;
}

.text18{
  font-size: 1.125em;
  line-height: 120%;
}

.text17{
  font-size: 1.0625em;
  line-height: 120%;
}

.subtitle1,
.subtitle2,
.overline{
  font-weight: 700;
}

.subtitle1,
.body-text,
.text16{
  font-size: 1em;
  line-height: 150%;
  letter-spacing: 0.01em;
}

.text15{
  font-size: .9375em;
  line-height: 120%;
}

.subtitle2,
.body-text2,
.text14{
  font-size: .875em;
  line-height: 143%;
  letter-spacing: 0.01em;
}

.text13{
  font-size: .8125em;
  line-height: 120%;
}

.overline,
.body-text3,
.text12{
  font-size: .75em;
  line-height: 133%;
  letter-spacing: 0.01em;
}

.text11{
  font-size: .6875em;
  line-height: 120%;
}

.text10{
  font-size: .625em;
  line-height: 120%;
}

.extra-bold-text{
  font-weight: 800;
}

.bold-text{
  font-weight: 700;
}

.semi-bold-text{
  font-weight: 600;
}

.medium-text{
  font-weight: 500;
}

.regular-text{
  font-weight: 400;
}

.light-text{
  font-weight: 300;
}

.primary-text{
  color: $primary;
}

.black64-text{
  color: $black-64;
}

.red-text{
  color: $red;
}


@media screen and (max-width: 1500px){
  .h3-laptop{
    font-size: 2.25em;
  }
}


@media screen and (max-width: 992px){
  .h1{
    font-size: 2.75em;
  }

  .h2{
    font-size: 2.25em;
  }

  .h3{
    font-size: 2em;
  }

  .h4{
    font-size: 1.3125em;
  }

  .h5{
    font-size: 1.0625em;
  }

  .number1{
    font-size: 4em;
  }

  .number2{
    font-size: 3em;
  }

  .subtitle1,
  .body-text{
    font-size: .9375em;
    line-height: 133%;
  }

  .subtitle2,
  .body-text2{
    font-size: .8125em;
    line-height: 138%;
  }

  .body-text3{
    font-size: .6875em;
    line-height: 145%;
  }

  .text20-tablet{
    font-size: 1.25em;
  }

  .text19-tablet{
    font-size: 1.1875em;
  }

  .text18-tablet{
    font-size: 1.125em;
  }

  .text17-tablet{
    font-size: 1.0625em;
  }

  .text16-tablet{
    font-size: 1em;
  }

  .text15-tablet{
    font-size: .9375em;
  }

  .text14-tablet{
    font-size: .875em;
  }

  .text13-tablet{
    font-size: .8125em;
  }

  .text12-tablet{
    font-size: .75em;
  }

  .text11-tablet{
    font-size: .6875em;
  }

  .text10-tablet{
    font-size: .625em;
  }

}

@media screen and (max-width: 576px){
  .h1{
    font-size: 2em;
  }

  .h2{
    font-size: 1.75em;
  }

  .h3{
    font-size: 1.5em;
  }

  .number1{
    font-size: 2.75em;
  }

  .number2{
    font-size: 2.25em;
  }
}
