.catalog{
  &__top{
    display: flex;
    align-items: flex-end;
  }
  &__count{
    margin-left: 1em;
    color: $black-40;
  }
  &__grid{
    display: grid;
    grid-template-columns: 16em calc(100% - 17em);
    gap: 1em;
    margin-top: 3em;
  }
}

.catalog-categories{
  margin-top: 3em;
  &__grid{
    row-gap: 1.5em;
  }
  .product-card{
    &__img{
      padding-bottom: 53.2%;
      margin-bottom: .5em;
      img{
        width: auto;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.mob-filter{
  &__top{
    display: none;
  }
}

.catalog-nav{
  &__back,
  &__current{
    display: flex;
    align-items: center;
    padding: .75em;
    min-height: 2.75em;
  }
  &__back{
    padding-left: 1em;
    svg{
      fill: $black;
      margin-right: .5em;
    }
  }
  &__current{
    background-color: $lightGrey;
    font-weight: 700;
    color: $primary;
  }
  &__links{
    padding-left: 2.25em;
    li{
      padding: .5em 0;
    }
    a{
      font-size: .875em;
      line-height: 143%;
      letter-spacing: 0.01em;
    }
  }
}

.mob-filter-btn{
  display: none;
}

.catalog-items{
  margin-top: -1em;
}

.product-card2{
  display: grid;
  grid-template-columns: 13.75em calc(100% - 14.75em);
  gap: .5em 1em;
  border-bottom: $border;
  padding: 1em 0;
  &__img{
    height: 10.875em;
    @include center;
    img{
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__articul{
    margin-top: .5em;
  }
  &__props{
    margin-top: .375em;
    display: flex;
    flex-wrap: wrap;
  }
  &__prop{
    margin: .125em .5em 0 0;
    &._full{
      width: 100%;
    }
  }
  &__nav{
    display: flex;
    margin-top: .75em;
  }
}

.catalog-desc{
  margin-top: 1.5em;
  &__nav{
    display: flex;
    margin-top: .75em;
  }
}


@media screen and (max-width: 1500px){

}


@media screen and (max-width: 992px){
  .catalog{
    &__grid{
      margin-top: 2em;
      grid-template-columns: 100%;
      gap: 0;
    }
  }

  .catalog-categories{
    .product-card{
      .subtitle2{
        font-size: .8125em;
        line-height: 138%;
      }
    }
  }

  .mob-filter{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $vh;
    background-color: $white;
    z-index: 100;
    transition: all .3s;
    transform: translateX(-120%);
    &__top{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 4.5em;
      padding: 0 1.5em;
    }
    &__close{
      svg{
        width: 1.5em;
        height: 1.5em;
        fill: $black-64;
      }
    }
    &__content{
      padding: 0 1.5em 1.5em;
      height: calc(100 * var(--vh) - 4.5em);
      overflow-y: auto;
    }
  }

  .catalog-nav{
    &__back,
    &__current{
      min-height: 2.625em;
      .body-text2{
        font-size: .8125em;
        line-height: 138%;
      }
    }
    &__back{
      svg{
        width: .625em;
        height: .625em;
      }
    }
    &__links{
      padding-left: 2.125em;
    }
  }

  .mob-filter-btn{
    display: flex;
    margin: .5em 0;
    width: 100%;
    svg{
      width: 1.125em;
      height: 1.1875em;
      margin-right: .5em;
    }
  }

  ._open-filter{
    .mob-filter{
      transform: translateX(0);
    }
  }

  .catalog-items{
    margin: 0;
  }

  .product-card2{
    grid-template-columns: 11.875em calc(100% - 12.875em);
    &__img{
      height: 9.375em;
    }
    .subtitle2,
    .body-text2{
      font-size: .8125em;
      line-height: 138%;
    }
  }
}


@media screen and (max-width: 576px){
  .catalog{
    &__top{
      display: block;
    }
    &__count{
      margin: .5em 0 0;
    }
  }

  .catalog-categories{
    margin-top: 2em;
    &__grid{
      row-gap: 0;
    }
    .product-card{
      display: flex;
      align-items: center;
      padding: .75em 0;
      text-align: left;
      &__img{
        padding: 0;
        width: 2.25em;
        height: 2.25em;
        flex-shrink: 0;
        margin: 0 1em 0 0;
      }
      .subtitle2{
        font-size: .9375em;
        line-height: 133%;
      }
    }
  }

  .mob-filter{
    &__top{
      padding: 0 1em;
      height: 3.5em;
    }
    &__content{
       padding: 0 1em 1em;
       height: calc(100 * var(--vh) - 3.5em);
    }
  }

  .product-card2{
    grid-template-columns: 100%;
  }

  .catalog-desc{
    margin-top: 1em;
  }
}
