.where-buy{
  padding-top: 4.25em;
  height: $vh;
  display: grid;
  grid-template-columns: 38.25em calc(100% - 38.25em);
  &__top{
    padding: 0 2em 0 3.75em;
  }
  .breadcrumbs{
    padding: .75em 0;
    margin: 0;
  }
  &__filter{
    display: flex;
    padding: 1.5em 0;
    position: relative;
    &-btn{
      margin-left: 1.25em;
      svg{
        width: 1.25em;
        height: 1.25em;
        margin-right: .5em;
      }
    }
    &._open{
      .where-buy__dropdown{
        opacity: 1;
        pointer-events: unset;
      }
      .where-buy__filter-btn{
        pointer-events: none;
      }
    }
  }
  &__dropdown{
    transition: all .3s;
    opacity: 0;
    pointer-events: none;
    margin-top: -1.375em;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: $white;
    padding: .5em .75em;
    @include grid100gap(.5em);
    box-shadow: 0 .25em 5em rgba(0, 0, 0, 0.16);
  }
  &__items{
    height: calc(100 * var(--vh) - 9em - 4.25em);
    overflow-y: auto;
    @include scroll;
    background-color: $lightGrey;
    padding: 0 1.875em 0 3.75em;
  }
  .map{
    width: 100%;
    height: 100%;
  }
}

.country-select{
  flex-grow: 1;
  position: relative;
  &._open{
    .country-select__show:after{
      transform: rotate(-180deg);
    }
    .country-select__dropdown{
      opacity: 1;
      pointer-events: unset;
    }
  }
  &__show{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3.25em;
    padding: 0 1em;
    background-color: $lightGrey;
    &:after{
      transition: all .3s;
      content: '';
      width: 1.25em;
      height: 1.25em;
      background-image: url(../img/arrow-bottom.svg);
      @include bgImgSize(100%);
      margin-left: .5em;
    }
  }
  &__dropdown{
    transition: all .3s;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: .25em;
    z-index: 5;
    background-color: $white;
    box-shadow: 0 .25em .5em rgba(0, 0, 0, 0.16);
    @include scroll;
    overflow-y: auto;
    max-height: 20.75em;
  }
}

.country-item{
  display: flex;
  align-items: center;
  padding: .5em .75em;
  transition: all .3s;
  &:hover{
    cursor: pointer;
    background-color: $lightGrey;
  }
  img{
    width: 1.5em;
    margin-right: .75em;
  }
}

.office-card{
  padding: 2em 0;
  border-bottom: $border;
  &:last-child{
    border-bottom: none;
  }
  &._gray{
    .office-card__title{
      color: $black-40;
    }
  }
  &__grid{
    display: grid;
    grid-template-columns: calc(100% - 9em) 8.75em;
    gap: .25em;
  }
  &__top{
    align-items: center;
  }
  &__title{
    display: flex;
    align-items: center;
    color: $primary;
    margin-bottom: .75em;
    img{
      width: 1.5em;
      margin-right: .75em;
    }
  }
  &__logo{
    img{
      width: 100%;
      display: block;
    }
  }
  &__bottom{
    margin-top: 1em;
    align-items: flex-end;
  }
  &__items{
    @include grid100gap(.75em);
  }
  &__item{
    display: flex;
    align-items: flex-start;
    &-icon{
      width: 1.5em;
      margin-right: .75em;
      flex-shrink: 0;
    }
    &-content{
      @include grid100gap(.25em);
    }
  }
  &__btn{
    margin-left: auto;
  }
}

.map{
  .place-card{
    padding-right: 2em;
  }
  .office-card__items{
    margin-top: .75em;
  }
  .ymaps-2-1-79-balloon{
    font-size: 1em;
    font-family: $font1;
    max-width: 100vw;
    //width: 22.5em;
    left: 50% !important;
    transform: translate(-51%,-2em);
    //box-sizing: border-box;
    ymaps{
      box-sizing: border-box;
    }
    &__tail{
      left: 50% !important;
      transform: translateX(-50%) rotate(-45deg) !important;
      width: 1.125em !important;
      height: 1.125em !important;
      margin-bottom: -1.125em !important;
    }
    &__layout{

    }
    &__content{
      width: 22.5em;
      font-size: 1em;
      font-family: inherit;
      //margin-right: 0 !important;
      margin-right: 0;
      padding: 1em;
      &>*{
        //width: 100% !important;
      }
    }
    &__close{
      width: 1.5em;
      height: 1.5em;
      position: absolute;
      top: .25em;
      right: .25em;
      &-button{
        width: 100%;
        height: 100%;
        opacity: .64;
        background-image: url(../img/close.svg);
        @include bgImgSize(100%);
      }
    }
  }
}

.where-buy-map{
  &__top{
    display: none;
  }
}


@media screen and (max-width: 1500px){
  .where-buy{
    grid-template-columns: 30.375em calc(100% - 30.375em);
    &__top,
    &__items{
      padding-left: 2em;
    }
  }
}


@media screen and (max-width: 992px){
  .where-buy{
    height: auto;
    grid-template-columns: 100%;
    &__top,
    &__items{
      padding-left: 1.5em;
      padding-right: 1.5em;
    }
    &__filter{
      &-btn{
        margin-left: 1em;
        span{
          display: none;
        }
        svg{
          margin: 0;
        }
      }
    }
    &__items{
      height: unset;
    }
  }

  .where-buy-map{
    opacity: 0;
    pointer-events: none;
    transition: all .3s;
    z-index: 50;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $vh;
    background-color: $white;
    &._open{
      opacity: 1;
      pointer-events: unset;
    }
    &__top{
      height: 4.75em;
      padding: 0 1.5em;
      display: flex;
    }
    &__close{
      svg{
        width: 1.5em;
        height: 1.5em;
      }
    }
    .map{
      height: calc(100% - 4.75em);
    }
  }
}


@media screen and (max-width: 576px){
  .where-buy {
    &__top,
    &__items {
      padding-left: 1em;
      padding-right: 1em;
    }
    &__filter{
      padding: 1em 0;
    }
  }

  .where-buy-map{
    &__top{
      height: 3.625em;
      padding: 0 1em;
    }
    .map{
      height: calc(100% - 3.625em);
    }
  }
}
