.page-top2{
  background-color: #001E56;
  color: $white;
  position: relative;
  background-size: 100% auto;
  background-position: 0 0;
  padding-bottom: 2.75em;
  @media screen and (max-width: 1920px){
    background-size: cover;
    background-position: center;
  }
  &__cover-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &:after{
      background: linear-gradient(90.00deg, rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.4) 45.69%,rgba(0, 0, 0, 0) 100%);
    }
    img{
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
  .container{
    position: relative;
    z-index: 2;
  }
  .breadcrumbs {
    margin: 0;
    li{
      &:not(:first-child) {
        &:before {
          background-image: url(../img/arrow-right.svg);
        }
      }
      &:last-child{
        color: $white-64;
      }
    }
  }
  &__inner{
    min-height: 44em;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  &__content{
    max-width: 42em;
  }
  &__desc{
    margin-top: .5em;
  }
}

.benefit{
  background-color: $white;
  &__inner{
    position: relative;
  }
  &__top{
    position: absolute;
    top: 0;
    left: 0;
    width: 37.5em;
    max-width: 100%;
  }
  &__items{
    width: 71.5em;
    max-width: 100%;
    margin-left: auto;
    display: grid;
    grid-template-columns: repeat(2,calc(50% - 1.25em));
    gap: 11.5em 2.5em;
    justify-content: flex-end;
  }
  .benefit-card{
    @media screen and (min-width: 993px){
      &:nth-child(1),
      &:nth-child(3){
        transform: translateY(20.25em);
      }
      &:nth-child(5){
        transform: translateX(calc(100% + 2.5em));
      }
    }
  }
}

.benefit-card{
  background-color: $lightGrey;
  &__img{
    @include imgCover(58%);
  }
  &__text{
    padding: 1.5em;
    @include grid100gap(1em);
  }
}

.become-provider{
  background-color: $footerColor;
  color: $white;
  padding: 4.375em 0;
  background-image: url(../img/become-provider.jpg);
  background-size: cover;
  background-position: center;
  &:after{
    background: linear-gradient(180.00deg, rgba(0, 0, 0, 0.36) 4.107%,rgba(0, 0, 0, 0.24) 64.127%,rgba(0, 0, 0, 0) 96.242%);
  }
  .container{
    position: relative;
    z-index: 2;
  }
  &__grid{
    margin-top: 2em;
  }
}

.step-item2{
  display: flex;
  align-items: center;
  &__num{
    width: 4.75em;
    height: 4.75em;
    @include center;
    background: rgba(0, 0, 0, 0.36);
    border: 1px solid $primary;
    flex-shrink: 0;
    color: $primary;
    margin-right: 1em;
  }
}


@media screen and (max-width: 1500px){
  .page-top2{
    &__inner{
      min-height: 32em;
    }
  }

  .benefit{
    &__items{
      width: 60em;
    }
  }

  .benefit-card{
    &__img{
      padding-bottom: 69.6%;
    }
  }
}


@media screen and (max-width: 992px){
  .page-top2{
    min-height: $vh;
    &__inner{
      min-height: unset;
      padding-top: 3.25em;
    }
  }

  .benefit{
    &__top{
      position: relative;
      width: 100%;
    }
    &__items{
      margin-top: 3em;
      width: 100%;
      grid-template-columns: repeat(2,calc(50% - .5em));
      gap: 1.5em 1em;
    }
  }

  .benefit-card{
    &__text{
      padding: 1em;
    }
  }

  .become-provider{
    padding: 3em 0;
  }

  .step-item2{
    &__num{
      width: 4.5em;
      height: 4.5em;
    }
  }
}


@media screen and (max-width: 576px){
  .page-top2{
    &__inner{
      padding-top: 1.5em;
    }
    .m-nav{
      margin-top: 1.5em;
    }
  }

  .benefit{
    padding: 4.5em 0;
    &__items{
      margin-top: 2em;
      grid-template-columns: 100%;
    }
  }

  .become-provider{
    padding: 2.25em 0;
  }

  .step-item2{
    &__num{
      width: 3.75em;
      height: 3.75em;
    }
  }
}
