.filter-slider{
  @include lastM(0 0 .75em);
  &__item{
    min-height: 1.5em;
    padding: 0 .125em;
    display: flex;
    align-items: center;
  }
  &__outputs{
    display: flex;
    justify-content: space-between;
    padding: .25em 0 .5em;
  }
  &__output{
    color: $primary;
    font-weight: 700;
    font-size: .75em;
    line-height: 133%;
    letter-spacing: 0.04em;
  }
  .range-slider{
    width: 100%;
    background-color: $lightGrey;
    border: none;
    box-shadow: none;
    height: .25em;
    border-radius: 0;
    .noUi-connect{
      background-color: $primary;
    }
    .noUi-handle{
      box-shadow: none;
      border: none;
      background-color: $primary;
      width: 1em;
      height: 1em;
      top: -.375em !important;
      right: -.375em !important;
      border-radius: 50%;
      cursor: pointer;
      &:before{
        opacity: 0;
        width: 300%;
        height: 100%;
        @include centerAbsolute;
      }
      &:after{
        display: none;
      }
    }
  }
}
