.categories{
  overflow: hidden;
  &__groups{
    @include grid100gap(8.75em);
  }
}

.slider{
  .swiper{
    &-wrapper{
      align-items: stretch;
    }
    &-slide{
      height: auto;
    }
  }
}

.swiper-button{
  border: 2px solid $primary;
  width: 4.5em;
  height: 3em;
  @include center;
  transition: all .3s;
  svg{
    width: 1.5em;
    height: 1.5em;
    fill: $primary;
  }
  &:hover{
    border-color: $lightBlue;
    background-color: $lightBlue;
    svg{
      fill: $white;
    }
  }
  &:active{
    border-color: $primary;
    background-color: $primary;
    svg{
      fill: $white;
    }
  }
}

.category-slider{
  .slider__wrap{
    overflow: hidden;
  }
  .swiper{
    &-container{
      width: 44.375em;
    }
    &-slide{
      display: flex;
      align-items: flex-end;
      padding-right: 2.5em;
      min-height: 35.75em;
      @media screen and (min-width: 993px){
        &-active~.swiper-slide{
          .category-card{
            width: 13.25em;
          }
        }
        &-next+.swiper-slide{
          .category-card{
            transform: translateX(-30.125em);
          }
        }
        &-next+.swiper-slide+.swiper-slide{
          .category-card{
            transform: translateX(-60.25em);
          }
        }
      }
    }
    &-nav{
      display: flex;
      justify-content: flex-end;
      margin-top: 1.5em;
    }
    &-button{
      position: static;
      margin: 0 0 0 1.5em;
    }
  }
}

.category-card{
  display: block;
  width: 100%;
  &__img{
    @include imgCover(77%);
  }
  &__text{
    padding: 1em 0;
  }
}

.category-group{
  position: relative;
  &__top{
    position: absolute;
    top: 0;
    left: 0;
    width: calc(50% - 1.25em);
    z-index: 3;
  }
  &__nav{
    display: flex;
    margin-top: 2em;
  }
  &:nth-child(odd){
    @media screen and (min-width: 993px){
      .category-slider{
        .swiper{
          &-container{
            margin-left: auto;
          }
          &-slide{
            padding-right: 0;
            .category-card{
              width: 13.25em;
              transform: translateX(-41.75em);
            }
            &-active,
            &-next,
            &-next+.swiper-slide{
              .h5{
                font-size: .875em;
                line-height: 143%;
                letter-spacing: 0.01em;
              }
            }
            &-active{
              .category-card{
                transform: translateX(-41.75em);
              }
            }
            &-next{
              .category-card{
                transform: translateX(-71.75em);
              }
            }
            &-next+.swiper-slide{
              .category-card{
                transform: translateX(-101.75em);
              }
            }
            &-next+.swiper-slide+.swiper-slide{
              .category-card{
                width: calc(100% - 2.5em);
                transform: translateX(-130.25em);
              }
            }
          }
        }
      }
    }
  }
  &:nth-child(even){
    @media screen and (min-width: 993px){
      .category-group__top{
        left: unset;
        right: 0;
      }
      .swiper-slide:not(.swiper-slide-active){
        .h5{
          font-size: .875em;
          line-height: 143%;
          letter-spacing: 0.01em;
        }
      }
    }
  }
}


@media screen and (max-width: 1500px){
  .category-slider{
    .swiper{
      &-container{
        width: 37.5em;
      }
      &-slide{
        min-height: 30.5em;
        @media screen and (min-width: 993px){
          &-active~.swiper-slide{
            .category-card{
              width: 11em;
            }
          }
          &-next+.swiper-slide{
            .category-card{
              transform: translateX(-25.5em);
            }
          }
          &-next+.swiper-slide+.swiper-slide{
            .category-card{
              transform: translateX(-51em);
            }
          }
        }
      }
    }
  }

  .category-group{
    &:nth-child(odd){
      @media screen and (min-width: 993px){
        .category-slider{
          .swiper{
            &-slide{
              .category-card{
                width: 11em;
                transform: translateX(-33.75em);
              }
              &-active{
                .category-card{
                  transform: translateX(-33.75em);
                }
              }
              &-next{
                .category-card{
                  transform: translateX(-59.25em);
                }
              }
              &-next+.swiper-slide{
                .category-card{
                  transform: translateX(-84.75em);
                }
              }
              &-next+.swiper-slide+.swiper-slide{
                .category-card{
                  transform: translateX(-110.25em);
                }
              }
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 992px){
  .categories{
    &__groups{
      gap: 6em;
    }
  }

  .category-group{
    padding-bottom: 5.125em;
    &__top{
      width: 100%;
      position: static;
      text-align: center;
      margin-bottom: 2em;
    }
    &__nav{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      justify-content: center;
    }
  }

  .category-slider{
    .slider__wrap{
      overflow: unset;
    }
    .swiper{
      &-container{
        width: 100%;
      }
      &-slide{
        padding-right: 1em;
        width: 35em;
        min-height: unset;
      }
      &-nav{
        display: none;
      }
    }
  }

  .category-card{
    &__text{
      padding-bottom: 0;
    }
  }
}


@media screen and (max-width: 576px){
  .categories{
    padding: 4.5em 0 6em;
    &__groups{
      gap: 5.25em;
    }
  }

  .category-group{
    &__link{
      width: 100%;
    }
  }

  .category-slider{
    .swiper{
      &-slide{
        width: 18.75em;
      }
    }
  }
}
