.header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  border-bottom: 1px solid $black-12;
  transition: all .3s;
  background-color: $white;
  &__inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo{
    width: 8.3125em;
    display: block;
    flex-shrink: 0;
    svg,
    img{
      width: 100%;
      display: block;
    }
  }
  &._white{
    border-color: $white-12;
    background-color: transparent;
    color: $white;
    &:before{
      transform: translateY(-100%);
    }
    .header__logo{
      path{
        fill: $white;
      }
    }
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: $white;
      transition: all .4s;
    }
    &:hover{
      @media screen and (min-width: 993px){
        color: $black;
        &:before{
          transform: translateY(0%);
        }
        .header__logo{
          path{
            fill: $black;
          }
          ._blue{
            fill: $primary;
          }
        }
      }
    }
    &._bg{
      background-color: $white;
      color: $black;
      .header__logo{
        path{
          fill: $black;
        }
        ._blue{
          fill: $primary;
        }
      }
    }
  }
  &._hide{
    //transform: translateY(-120%);
    top: -5em;
    .catalog-menu,
    .nav-item__hidden{
      //opacity: 0 !important;
      //pointer-events: none !important;
    }
  }
}



.burger{
  display: none;
}

._flex,
._flex-center,
._flex-between{
  display: flex;
  align-items: center;
}

._flex-center{
  justify-content: center;
}

._flex-between{
  justify-content: space-between;
}

.menu{
  height: 100%;
  align-items: stretch;
}

.nav{
  display: flex;
  align-items: flex-end;
  margin-bottom: -1px;
}

.nav-item__show,
.nav-link{
  padding: 0 1em;
  height: 4.25em;
  display: flex;
  align-items: center;
  font-weight: 700;
  position: relative;
  &:after{
    content: '';
    position: absolute;
    height: .1875em;
    width: 100%;
    background-color: $lightBlue;
    left: 0;
    bottom: 0;
    transition: all .3s;
    opacity: 0;
    pointer-events: none;
  }
  span{
    font-size: .875em;
    line-height: 143%;
    letter-spacing: 0.01em;
  }
  &:hover,
  &:active{
    color: $primary;
    &:after{
      opacity: 1;
    }
  }
  &:active{
    &:after{
      background-color: $primary;
    }
  }
}

.nav-item{
  &__show{
    cursor: pointer;
  }
  &__back{
    display: none;
  }
  @media screen and (min-width: 993px){
    &:hover{
      .nav-item__hidden{
        opacity: 1;
        pointer-events: unset;
      }
      .nav-item__show{
        color: $primary;
        &:after{
          background-color: $primary;
        }
      }
    }
    &__show{
      position: relative;
      &:before{
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        top: 100%;
        left: 0;
        margin-top: -1px;
      }
    }
    &__hidden{
      transition: all .3s;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      //margin-top: 1px;
      //box-shadow: 0 .125em .5em 0 rgba(0, 0, 0, 0.16);
      background-color: $lightGrey;
      border-top: 1px solid $black-12;
    }
  }
}

.about-menu{
  overflow: hidden;
  color: $black;
  &__inner{
    display: grid;
    grid-template-columns: repeat(2,calc(50% - .5em));
    gap: 1em;
  }
  &__col{
    padding: 2.5em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__col2{
    position: relative;
    padding: 2em 0;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: -1em;
      width: 55vw;
      height: 100%;
      background-color: $white;
    }
  }
  &__links{
    position: relative;
    z-index: 2;
    li{
      padding: .75em 1em;
    }
    a{
      font-size: 1em;
      line-height: 150%;
      letter-spacing: 0.01em;
    }
  }
  &__img{
    width: 26.25em;
    display: block;
    max-width: 100%;
  }
}


@media screen and (max-width: 1500px){

}


@media screen and (max-width: 992px){
  .header{
    &__logo{
      width: 7.5em;
    }
    &._white{
      &:before{
        display: none;
      }
      &._open{
        background-color: $white;
        color: $black;
        .header__logo{
          path{
            fill: $black;
          }
          ._blue{
            fill: $primary;
          }
        }
      }
      .burger{
        svg{
          fill: $white;
        }
      }
      &._bg{
        .burger{
          svg{
            fill: $black;
          }
        }
      }
    }
    &._open{
      .nav{
        opacity: 1;
        pointer-events: unset;
      }
      .burger{
        ._view1{
          opacity: 0;
        }
        ._view2{
          opacity: 1;
          fill: $black;
        }
      }
    }
  }

  .nav{
    position: fixed;
    height: calc(100 * var(--vh) - 3.75em);
    width: 100%;
    background-color: $white;
    border-top: $border;
    left: 0;
    bottom: 0;
    display: block;
    transition: all .3s;
    opacity: 0;
    pointer-events: none;
    overflow-y: auto;
  }

  .nav-link,
  .nav-item__show{
    height: 3.125em;
    padding: 0 1.5em;
    &:after{
      display: none;
    }
  }

  .nav-item{
    &._open{
      .nav-item__hidden{
        transform: translateX(0);
      }
    }
    &__show{
      background-image: url(../img/arrow-right-black.svg);
      background-repeat: no-repeat;
      background-position: right 1.5em center;
      background-size: 1.125em auto;
    }
    &__back{
      display: flex;
      align-items: center;
      padding: 1em 1.5em;
      font-weight: 700;
      background-color: $white;
      &:before{
        content: '';
        width: 1.125em;
        height: 1.125em;
        background-image: url(../img/arrow-left-black.svg);
        @include bgImgSize(100%);
        margin-right: 1em;
      }
      span{
        font-size: .8125em;
        line-height: 138%;
        letter-spacing: 0.01em;
      }
    }
    &__hidden{
      z-index: 5;
      overflow-y: auto;
      position: fixed;
      height: calc(100 * var(--vh) - 3.75em - 1px);
      width: 100%;
      background-color: $lightGrey;
      left: 0;
      bottom: 0;
      top: unset;
      transition: all .3s;
      transform: translateX(-100%);
      .container{
        padding: 0;
      }
    }
  }

  .about-menu{
    &__inner{
      display: block;
    }
    &__col{
      display: none;
    }
    &__col2{
      padding: 0;
      &:before{
        display: none;
      }
    }
    &__links{
      li{
        padding: .75em 1.5em;
      }
      a{
        font-size: .8125em;
        line-height: 138%;
      }
    }
  }
}


@media screen and (max-width: 576px){
  .nav-link,
  .nav-item__show{
    padding: 0 1em;
  }

  .nav-item{
    &__back{
      padding: 1em;
    }
    &__show{
      background-position: right 1em center;
    }
  }

  .about-menu{
    &__links{
      li{
        padding: .75em 1em;
      }
    }
  }
}
