.history-top {
    position: relative;
    z-index: 2;
    color: $white;
    background-color: $black;
    .breadcrumbs {
        margin-bottom: 4.75em;
        li {
            &:not(:first-child) a {
                &:before {
                    background-image: url(../img/arrow-right.svg);
                }
            }
            &:last-child {
                color: $white-64;
            }
        }
    }
}

.history {
    padding-top: 17em;
    margin-top: -7.5em;
    padding-bottom: 7.5em;
    color: $white;
    background-color: $black;
    flex-grow: 1;
    position: relative;
    &__inner {
        position: relative;
        padding-top: 17.5em;
    }
    &__groups {
        @include grid100gap(7.75em);
        position: relative;
        z-index: 2;
    }
}

.history-path {
    position: absolute;
    top: 0;
    left: 5.75em;
    width: 38.75em;
    #car-path2 {
        transition: all 0s;
    }
    &__anim-target {
        position: absolute;
        top: 1em;
        height: 380em;
        min-height: calc(100% - 3em);
        width: 5px;
        opacity: 0;
    }
    &__line3,
    &__line2,
    &__line1 {
        width: 100%;
        overflow: unset !important;
    }
    &__line4,
    &__line3,
    &__line2 {
        position: absolute;
        top: 0;
        left: 0;
    }
    &__line4,
    &__line3 {}
    &__line2 {
        path {
            transition: all .1s;
        }
    }
    &__line4 {
        z-index: 3;
    }
    &__progress {
        position: absolute;
        top: 0;
        left: 0;
        height: 50%;
    }
    &__car {
        position: absolute;
        top: 0;
        right: 1.5em;
        width: 1.625em;
        //transform: rotate(-45deg);
        svg {
            width: 100%;
        }
    }
}

.history-group {
    width: 50.375em;
    padding-left: 9.125em;
    position: relative;
    &__year {
        position: absolute;
        top: 0;
        left: 0;
        width: 5.625em;
        height: 2em;
        background-color: $darkBlue;
        @include center;
    }
    &__img {
        max-width: 32.25em;
        margin-bottom: 1em;
        img {
            width: 100%;
            display: block;
        }
    }
    &__stat {
        position: absolute;
        right: 0;
        top: 1.5em;
        min-width: 13.125em;
        padding: 1em;
        background-color: $darkBlue;
        &-item {
            display: flex;
            align-items: center;
            padding-bottom: .5em;
            margin-bottom: .5em;
            border-bottom: $border2;
            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border: none;
            }
            .h3 {
                margin-right: .44em;
            }
        }
    }
    &__text {
        @include grid100gap(.5em);
    }
    &__logo {
        width: 15.8125em;
        margin-bottom: .5em;
    }
    &__logo2 {
        width: 14.625em;
    }
    @media screen and (min-width: 993px) {
        &:nth-child(1) {
            margin-left: 14.375em;
        }
        &:nth-child(2) {
            margin-left: 27.5em;
        }
        &:nth-child(3) {
            margin-left: 21em;
        }
        &:nth-child(4) {
            margin-left: 30.875em;
        }
        &:nth-child(5) {
            margin-left: 24.375em;
        }
        &:nth-child(6) {
            margin-left: 22.6875em;
        }
        &:nth-child(7) {
            margin-left: 15em;
        }
        &:nth-child(8) {
            margin-left: 13.5em;
        }
        &:nth-child(9) {
            margin-left: 21.25em;
        }
        &:nth-child(10) {
            margin-left: 15.25em;
        }
        &:nth-child(11) {
            margin-left: 24.375em;
        }
        &:nth-child(12) {
            margin-left: 13.75em;
        }
        &:nth-child(13) {
            margin-left: 24.375em;
        }
        &:nth-child(14) {
            margin-left: 15.625em;
        }
    }
}

.history-stat {
    display: flex;
    margin-bottom: .5em;
    text-align: center;
    &__item {
        color: $primary;
        padding-right: 2em;
        margin-right: 2em;
        border-right: $border2;
        &:last-child {
            margin-right: 0;
            padding-right: 0;
            border: none;
        }
    }
    &._type2 {
        display: block;
        .history-stat__item {
            display: flex;
            align-items: center;
            .number1 {
                margin-right: .22em;
            }
        }
    }
}

.history-group2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 41.25em;
    max-width: 100%;
    margin: 0 auto;
    .history-group__year {
        position: static;
        margin: 0 0 3.5em;
    }
}

@media screen and (max-width: 1500px) {
    .history {
        &__groups {
            margin-left: -5em;
        }
    }
    .history-path {
        left: 0;
    }
    .history-top,
    {
        .breadcrumbs {
            margin-bottom: 1.5em;
        }
    }
    .history-group {
        @media screen and (min-width: 993px) {
            &:nth-child(2) {
                margin-left: 19.75em;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .history {
        padding-bottom: 7.5em;
        padding-top: 7.5em;
        &__inner {
            padding-top: 0;
            margin-top: 0;
        }
        &__groups {
            gap: 6em;
            margin-top: 4.5em;
            margin-left: 0;
        }
    }
    .history-path {
        display: none;
    }
    .history-group {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        &__year {
            position: static;
            order: 1;
            height: 2.25em;
            width: 6em;
            .h5 {
                font-size: 1.3125em;
            }
        }
        &__text {
            order: 2;
            margin-top: 2em;
            text-align: center;
            gap: 1.5em;
        }
        &__img {
            width: 100%;
            max-width: 100%;
            order: 4;
            margin-bottom: 0;
        }
        &__stat {
            position: static;
            width: 100%;
            order: 3;
            display: grid;
            grid-template-columns: 50% 50%;
            margin-top: 2em;
            &-item {
                border-bottom: none;
                border-right: $border2;
                justify-content: center;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
        &__logo,
        &__logo2 {
            width: 12.625em;
            margin-left: auto;
            margin-right: auto;
        }
        &__logo2 {
            width: 11.625em;
            margin-bottom: .5em;
        }
    }
    .history-stat {
        display: grid;
        grid-template-columns: 50% 50%;
        margin-bottom: 1em;
        &__item {
            padding-right: 0;
            margin-right: 0;
        }
    }
    .history-group2 {
        .history-group__year {
            margin-bottom: 2em;
        }
    }
}

@media screen and (max-width: 576px) {
    .history {
        padding-bottom: 3.75em;
        &__groups {
            gap: 4.5em;
            margin-top: 4.5em;
        }
    }
    .history-group {
        &__text {
            margin-top: 1.5em;
            gap: 1em;
        }
        &__stat {
            &-item {
                flex-direction: column;
                .h3 {
                    margin: 0 0 .33em;
                }
            }
        }
        &__year {
            height: 1.875em;
            width: 5.5em;
            .h5 {
                font-size: 1.0625em;
            }
        }
        &__logo {
            width: 11em;
            margin-bottom: 1em;
        }
        &__logo2 {
            width: 10.25em;
            margin-bottom: 1em;
        }
    }
    .history-stat {
        margin-bottom: .5em;
    }
    .history-group2 {
        .history-group__year {
            margin-bottom: 1.5em;
        }
    }
}