.tabs{
  display: flex;
}

.tab{
  height: 3.5625em;
  padding: 0 1.56em;
  display: flex;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  color: rgba($black,.8);
  transition: all .3s;
  @include lastM(0 .94em 0 0);
  &:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 0;
    background-color: $primary;
    opacity: 0;
    transition: inherit;
  }
  span{
    font-size: .875em;
    line-height: 120%;
  }
  &:hover{
    background: #FBFBFB;
    cursor: pointer;
  }
  &._active{
    background: #FBFBFB;
    pointer-events: none;
    &:before{
      opacity: 1;
    }
  }
}

.tab-content{
  display: none;
  &._active{
    display: block;
  }
}
