.footer{
  background-color: $footerColor;
  color: $white;
  position: relative;
  z-index: 2;
  &__logo{
    display: block;
    width: 15.125em;
    img{
      width: 100%;
      display: block;
    }
  }
}

.footer-top{
  padding: 4.375em 0 5em;
  border-bottom: $border2;
  &__nav{
    display: flex;
    margin-top: 1.5em;
  }
}

.m-link{
  color: inherit;
  &:hover{
    color: $lightBlue;
  }
  &:active{
    color: $primary;
  }
}

.footer-bottom{
  padding: 4.375em 0;
  display: flex;
  justify-content: space-between;
}

.footer-info{
  min-height: 18em;
  display: flex;
  flex-direction: column;
  &__links{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 2em;
    margin-top: auto;
    color: $white-64;
  }
  &__link{
    margin-bottom: 1em;
    &:last-child{
      margin: 0;
    }
  }
}

.footer-about__top,
.footer-nav__link{
  font-weight: 700;
  display: flex;
  align-items: center;
  transition: all .3s;
  &:before{
    content: '';
    width: .375em;
    height: .375em;
    flex-shrink: 0;
    background-color: $lightBlue;
    margin-right: 1em;
  }
  span{
    font-size: 1.125em;
    line-height: 133%;
  }
}

.footer-nav{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding-right: 21.25em;
  min-width: 42.625em;
  &__link{
    margin-bottom: 1.5em;
    &:hover{
      color: $lightBlue;
    }
    &:last-child{
      margin: 0;
    }
  }
}

.footer-about{
  position: absolute;
  top: 0;
  right: 0;
  width: 20.75em;
  &__top{
    pointer-events: none;
  }
  &__bottom{
    padding: 1.5em 0 0 1.375em;
    @media screen and (min-width: 993px){
      display: block !important;
    }
  }
  &__links{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: $white-64;
  }
  &__link{
    margin-bottom: .75em;
  }
}

.float-buttons{
  z-index: 10;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  &__item{
    height: 4em;
    width: 6.5em;
    @include center;
    flex-direction: column;
    &:not(:last-child){
      border-bottom: 1px solid $white-12 !important;
    }
    svg{
      width: 1.5625em;
      height: 1.5em;
      margin-bottom: .25em;
    }
    span{
      font-size: .875em;
      line-height: 143%;
      letter-spacing: 0.01em;
    }
  }
}

.nav-bar{
  display: none;
}

.ceo-content{
  position: relative;
  z-index: 2;
  &:not(._light){
    background-color: $footerColor;
    color: $white;
  }
  .footer-top{
    border: none;
  }
}


@media screen and (max-width: 1500px){
  .footer-nav{
    min-width: 35.75em;
    padding-right: 18.375em;
  }

  .footer-about{
    width: 18.375em;
  }
}


@media screen and (max-width: 992px){
  .footer{
    padding-bottom: 4.5em;
    &__logo{
      width: 10.0625em;
    }
  }

  .footer-top{
    padding: 3em 0;
  }

  .footer-bottom{
    padding: 3em 0 4.25em;
    @include grid100gap(2em);
    position: relative;
  }

  .footer-info{
    min-height: unset;
    &__links{
      position: absolute;
      flex-direction: row;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 0 1em;
    }
    &__link{
      margin: 0 2.13em 0 0;
    }
  }

  .footer-nav__link,
  .footer-about__top{
    padding-bottom: 1em;
    span{
      font-size: 1.0625em;
    }
  }

  .footer-nav{
    padding-right: 0;
    min-width: unset;
    &__link{
      margin-bottom: 1em;
      border-bottom: 1px solid $white-12;
      width: 100%;
    }
  }

  .footer-about{
    position: static;
    width: 100%;
    margin-bottom: 1em;
    border-bottom: 1px solid $white-12;
    &._open{
      .footer-about__top:after{
        transform: rotate(90deg);
      }
    }
    &__top{
      pointer-events: unset;
      &:after{
        content: '';
        flex-shrink: 0;
        width: 1.25em;
        height: 1.25em;
        background-image: url(../img/arrow-right.svg);
        @include bgImgSize(100%);
        margin-left: auto;
        transition: all .3s;
      }
    }
    &__bottom{
      display: none;
      padding-top: .5em;
      padding-bottom: 1em;
    }


    @media screen and (min-width: 577px){
      &__links{
        display: grid;
        grid-template-columns: repeat(2,calc(50% - .5em));
        gap: 1em;
      }
      &__link{
        margin: 0;
      }
    }
  }

  .float-buttons{
    display: none;
  }

  .nav-bar{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: .5em 1.5em 1em;
    background-color: $footerColor;
    box-shadow: .0625em .0625em 1em rgba(0, 10, 18, 0.16), 1px 1px 2px 0 rgba(0, 10, 18, 0.01);
    z-index: 30;
    display: grid;
    grid-template-columns: repeat(4,25%);
    &__link{
      padding-top: .25em;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $white;
      border-right: $border2;
      &:hover{
        color: $white;
      }
      &:last-child{
        border-right: none;
      }
      img{
        margin-bottom: .25em;
        height: 1.5em;
      }
    }
  }
}


@media screen and (max-width: 576px){
  .footer{
    &__logo{
      width: 8.625em;
    }
  }

  .footer-top{
    padding: 2.25em 0;
  }

  .footer-bottom{
    padding: 2.25em 0 6.25em;
  }

  .footer-info{
    &__links{
      flex-direction: column;
    }
    &__link{
      margin: 0 0 1.23em;
      font-size: .8125em;
    }
  }

  .nav-bar{
    padding-left: 1em;
    padding-right: 1em;
    &__link{
      span{
        font-size: .6875em;
        line-height: 145%;
        letter-spacing: 0.05em;
      }
    }
  }
}
