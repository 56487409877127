.quality{
  &__items{
    margin-top: 3em;
  }
  .advantage-item2{
    min-height: 22.5em;
    &__inner{
      padding: 1.375em;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &__icon{
      order: 2;
      margin: auto 0 0;
      height: 5em;
    }
    &__text{
      margin-bottom: 1em;
    }
  }
}

.brand-history{
  overflow: hidden;
  &__grid{
    display: grid;
    grid-template-columns: 34.5em calc(100% - 37em);
    gap: 2em 2.5em;
    align-items: center;
  }
  &__img{
    width: calc(100% + 3.75em);
    @media screen and (min-width: 1501px){
      width: calc(100% + (100vw - 1500px) / 2 + 3.75em);
    }
    img{
      width: 100%;
      display: block;
    }
  }
}

.video{
  display: block;
  position: relative;
  &:after{
    background: rgba($black,.36);
  }
  &__img{
    width: 100%;
    display: block;
  }
  &__play{
    z-index: 2;
    @include centerAbsolute;
    @include center;
    transition: all .3s;
    opacity: .64;
    width: 6em;
    img{
      width: 100%;
      display: block;
    }
    &:hover{
      opacity: 1;
    }
  }
}


@media screen and (max-width: 1500px){
  .brand-history{
    &__grid{
      grid-template-columns: 28.75em calc(100% - 31.25em);
    }
    &__img{
      @media screen and (min-width: 1281px){
        width: calc(100% + (100vw - 1280px) / 2 + 3.75em);
      }
    }
  }
}


@media screen and (max-width: 992px){
  .quality{
    &__items{
      margin-top: 2em;
    }
    .advantage-item2{
      min-height: 13em;
      &__inner{
        padding: .875em;
      }
      &__icon{
        height: 4em;
      }
    }
  }

  .brand-history{
    padding: 6em 0 3em;
    &__grid{
      grid-template-columns: 100%;
    }
    &__img{
      width: 100%;
    }
  }

  .brand-video{
    padding-top: 3em;
  }

  .video{
    &__play{
      width: 5em;
    }
  }
}


@media screen and (max-width: 576px){
  .quality{
    &__inner{
      position: relative;
      padding-bottom: 5.125em;
    }
    &__link{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }

  .brand-history{
    padding: 4.5em 0 2.25em;
  }

  .brand-video{
    padding-top: 2.25em;
  }

  .video{
    &__play{
      width: 4em;
    }
  }
}
