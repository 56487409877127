.quality-control-top{
  @media screen and (min-width: 993px){
    .page-top__content{
      max-width: 49.25em;
    }
  }
}

.control-stages{
  background-color: $black;
  color: $white;
  .benefit-card{
    background: linear-gradient(-45.00deg, rgba(255, 255, 255, 0.06) 0%,rgba(255, 255, 255, 0.02) 100%);
    .h4{
      color: $primary;
    }
  }
}

.defect-percent{
  padding: 4.375em 0;
  background-color: $footerColor;
  color: $white;
  position: relative;
  //background-image: url(../img/defect-percent-bg.jpg);
  //background-size: cover;
  //background-position: center;
  &__img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
    ._view2{
      display: none;
    }
  }
  .container{
    z-index: 2;
    position: relative;
  }
  &__content{
    margin-left: auto;
    max-width: 49.25em;
  }
}

.product-advantages{
  @media screen and (min-width: 993px){
    .advantages__img{
      grid-row: 1/4;
    }
  }
}

.product-detail{
  @include grid100gap(8.75em);
}

.product-info2{
  &__grid{
    display: grid;
    grid-template-columns: repeat(2,calc(50% - 1.25em));
    gap: 2.5em;
    align-items: center;
  }
  &__text{
    margin-top: 1.5em;
  }
}

._dark-block{
  .gallery2{
    &__small{
      .gallery2__img:after{
        background-color: rgba($black,.36);
      }
    }
  }
}

.gallery2{
  &__img{
    @include imgCover(62.6%);
    display: block;
  }
  &__big{
    position: relative;
  }
  &__small{
    margin-top: 1.5em;
    overflow: hidden;
    .swiper{
      width: calc(100% + 1.5em);
      &-slide{
        padding-right: 1.5em;
        width: 25%;
        &-thumb-active{
          .gallery2__img:before{
            opacity: 1;
          }
          .gallery2__img:after{
            opacity: 0;
          }
        }
      }
    }
    .gallery2__img{
      cursor: pointer;
      &:before,
      &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all .3s;
      }
      &:after{
        z-index: 3;
        background-color: rgba($white,.36);
      }
      &:before{
        opacity: 0;
        z-index: 2;
        border: 1px solid $primary;
      }
    }
  }
  .swiper{
    &-button{
      margin: 0;
      top: 50%;
      transform: translateY(-50%);
      &-prev{
        left: .5em;
      }
      &-next{
        right: .5em;
      }
    }
    @media screen and (min-width: 993px){
      &-nav{
        display: none;
      }
    }
  }
}

.product-advantages2{
  &__grid{
    margin-top: 3em;
    row-gap: 2.5em;
  }
}

.product-advantages3{
  .advantages__grid{
    margin-top: 2.5em;
  }
}


@media screen and (max-width: 1500px){

}


@media screen and (max-width: 992px){
  .defect-percent{
    min-height: 30em;
    padding: 3em 0;
    &__img{
      ._view1{
        display: none;
      }
      ._view2{
        display: block;
      }
    }
  }

  .product-detail{
    gap: 6em;
  }

  .product-info2{
    &__grid{
      grid-template-columns: 100%;
    }
  }

  .product-advantages2{
    &__grid{
      margin-top: 2em;
      row-gap: 1em;
    }
  }
}


@media screen and (max-width: 576px){
  .defect-percent{
    padding: 2.25em 0;
  }

  .product-detail{
    gap: 4.5em;
  }

  .product-info2{
    &__text{
      margin-top: 1em;
    }
  }

  .gallery2{
    &__small{
      margin-top: 1em;
      .swiper{
        width: calc(100% + 1em);
        &-slide{
          padding-right: 1em;
        }
      }
    }
    .swiper{
      &-button{
        width: 2.75em;
        height: 2em;
        svg{
          width: 1.25em;
          height: 1.25em;
        }
      }
    }
  }
}
