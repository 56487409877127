.catalog-item{
  padding: 0 0 3em;
  &__grid{
    display: grid;
    grid-template-columns: calc(100% - 37em) 34.5em;
    gap: 1.5em 2.5em;
  }
}

.main-info{
  margin-top: 1.5em;
  &__items{
    margin-top: 1em;
    @include grid100gap(.5em);
  }
  &__item{
    display: grid;
    grid-template-columns: repeat(2,calc(50% - .125em));
    gap: .25em;
    &-title{
      color: $black-64;
    }
  }
}

.product-info{
  margin-top: 1.5em;
  background-color: $lightGrey;
  &__tabs{
    display: flex;
    border-bottom: .125em solid $black-12;
  }
  &__tab{
    height: 3.25em;
    padding: 0 1em;
    display: flex;
    align-items: center;
    color: $black-40;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: .125em;
      background-color: $black-12;
      transition: all .3s;
    }
    &._active{
      color: $primary;
      &:before{
        background-color: $primary;
      }
    }
    &:hover{
      cursor: pointer;
      color: $primary;
    }
  }
  &__content{
    padding: 1em;
  }
  .main-info{
    margin: 0;
  }
}

.product-usability{
  &__items{
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 1em);
  }
  &__item{
    padding: 0 .6875em;
    height: 1.75em;
    display: flex;
    align-items: center;
    border: 1px solid $black-40;
    margin: 1em 1em 0 0;
    text-transform: uppercase;
    transition: all .3s;
    &:hover{
      color: $lightBlue;
      border-color: $lightBlue;
    }
    &:active{
      color: $primary;
      border-color: $primary;
    }
  }
}

.gallery{
  display: grid;
  grid-template-columns: 4em calc(100% - 5.25em);
  gap: 1.25em;
  .swiper{
    height: 22.75em;
  }
  &__small{
    .swiper{
      &-slide{
        height: 4.5em;
        padding-bottom: .5em;
        &-thumb-active{
          .gallery__img{
            border-color: $primary;
          }
        }
      }
    }
    .gallery__img{
      padding: .1875em;
      border: 1px solid transparent;
      img{
        max-width: calc(100% - .375em);
        max-height: calc(100% - .375em);
      }
    }
  }
  &__img{
    width: 100%;
    height: 100%;
    @include center;
    position: relative;
    img{
      max-width: 100%;
      max-height: 100%;
      @include centerAbsolute;
    }
  }
}


@media screen and (max-width: 1500px){
  .catalog-item{
    &__grid{
      grid-template-columns: calc(100% - 31.25em) 28.75em;
    }
  }
}


@media screen and (max-width: 992px){
  .catalog-item{
    &__grid{
      grid-template-columns: 100%;
    }
  }

  .main-info{
    margin-top: 1em;
    &__items{
      margin-top: .5em;
    }
    &__item{
      &-title,&-desc{
        font-size: .8125em;
        line-height: 138%;
      }
    }
  }

  .product-info{
    margin-top: 1em;
    &__tab{
      height: 3.125em;
    }
  }

  .product-usability{
    &__item{
      height: 1.625em;
    }
  }
}


@media screen and (max-width: 576px){
  .gallery{
    grid-template-columns: 100%;
    .swiper{
      height: 14.25em;
    }
    &__small{
      order: 2;
      .swiper{
        height: 3.5em;
        &-slide{
          width: 4em;
          height: 100%;
          padding: 0 .5em 0 0;
        }
      }
    }
  }
}
