.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
  width: auto;
  background: #ffffff !important;
  padding: 0 10px;
  font-size: 13px;
  color: #212121;
  height: 22px;
  line-height: 22px;
  background: #ffffff !important;
  border: 1px solid #e0e0e0;
  width: 142px;
  font-weight: 300;
}
.ncatlist .ncatfilterselect .nice-select, .cartselect.nice-select{
  width: 100%;
}
.shoplist .shoplist-menu .nice-select{
  width: 380px;
    height: 52px;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    line-height: 52px;
    padding: 0 15px;
    color: #cccccc;
}
.shoplist .shoplist-menu .nice-select:after{
    background: url(../images/seldropblack.png) no-repeat;
    content: "";
    position: absolute;
    z-index: 1;
    right: 15px;
    top: 50%;
    width: 13px;
    height: 13px;
    display: block;
    margin-top: -7px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
.shoplist .shoplist-menu .nice-select.open:after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg); }
  .nice-select:hover {
    border-color: #dbdbdb; }
  .nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: #999; }
  .nice-select:after {
        content: "";
    position: absolute;
    z-index: 7;
    top: 2px;
    right: 10px;
    width: 8px;
    height: 16px;
    cursor: pointer;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background-size: 4px auto; }
  .nice-select.open:after {
    -webkit-transform: rotate(-270deg);
            transform: rotate(-270deg); }
  .nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0); }
  .nice-select.disabled {
    border-color: #ededed;
    color: #999;
    pointer-events: none; }
    .nice-select.disabled:after {
      border-color: #cccccc; }
  .nice-select.wide {
    width: 100%; }
    .nice-select.wide .list {
      left: 0 !important;
      right: 0 !important; }
  .nice-select.right {
    float: right; }
    .nice-select.right .list {
      left: auto;
      right: 0; }
  .nice-select.small {
    font-size: 12px;
    height: 36px;
    line-height: 34px; }
    .nice-select.small:after {
      height: 4px;
      width: 4px; }
    .nice-select.small .option {
      line-height: 34px;
      min-height: 34px; }
  .nice-select .list {
    background-color: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9; }
  .nice-select .option {
    cursor: pointer;
    font-weight: 400;
    list-style: none;
    outline: none;
    padding-left: 18px;
    padding-right: 29px;
    padding: 6px 10px;
    font-size: 12px;
    line-height: 1;
    border-top: 1px dotted #e0e0e0;
    text-align: left;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
    .nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
      background-color: #f6f6f6; }
    .nice-select .option.selected {
      font-weight: bold; width: 100%;}
    .nice-select .option.disabled {
      background-color: transparent;
      cursor: default; }

.no-csspointerevents .nice-select .list {
  display: none; }

.no-csspointerevents .nice-select.open .list {
  display: block; }

.cartFormBuy .nice-select,.cartFormBuy .cartselect{
  height: 40px;
  line-height: 40px;
  margin-bottom: 30px;
}
.cartFormBuy .nice-select:after{
  top: 12px;
}
.cartwrap .cartFormWrap .cartFormBuy .tabcart .cartselect.mt20 .nice-select{
  height: 40px;
  line-height: 40px;
  margin-bottom: 30px;
}
.cartwrap .cartFormWrap .cartFormBuy .tabcart .cartselect.mt20 .nice-select:after{
  top: 12px;
}

