.leader{
  padding: 0 3.75em;
  overflow: hidden;
  background-color: $black;
  &__wrap{
    position: relative;
    z-index: 2;
    background-image: url(../img/leader.webp);
    background-size: cover;
    background-position: center;
    color: $white;
    background-color: $footerColor;
    &:after{
      background: linear-gradient(90.57deg, rgba(0, 18, 51, 0.32) 0.067%,rgba(0, 18, 51, 0.32) 99.896%),linear-gradient(90.00deg, rgba(0, 51, 113, 0) 0.037%,rgba(0, 18, 51, 0.8) 64.486%);
    }
  }
  &__line{
    position: absolute;
    top: 0;
    width: 3.75em;
    height: 100%;
    background-color: $white;
  }
  &__line1{
    right: 100%;
  }
  &__line2{
    left: 100%;
  }
  &__inner{
    overflow: hidden;
    padding: 4.375em 0;
    height: 52.5em;
    position: relative;
    z-index: 2;
  }
  &__content{
    max-width: 34.5em;
  }
  &__desc{
    margin-top: 1.5em;
  }
  &__items{
    width: 32.25em;
    align-self: flex-start;
  }
  .advantage-item2{
    margin-bottom: 3.5em;
    flex-shrink: 0;
    &:last-child{
      margin-bottom: 0;
    }
    &:before{
      border: $border2;
      background: transparent;
    }
    &:after{
      display: none;
    }
    &:hover{
      &:before{
        opacity: 1;
      }
    }
    &__inner{
      padding: 1.375em;
      background: linear-gradient(-45.00deg, rgba(255, 255, 255, 0.06),rgba(255, 255, 255, 0.02) 100%);
    }
  }
}

.marketing{
  background-color: $black;
  color: $white;
  &__top{
    max-width: 49em;
  }
  &__items{
    margin-top: 3em;
    @include grid100gap(2.5em);
  }
  .marketing-card{
    &:nth-child(odd){
      margin-left: auto;
    }
    &:nth-child(even){
      @media screen and (min-width: 577px){
        .marketing-card__img{
          order: 2;
        }
      }
    }
  }
}

.marketing-card{
  width: calc((100% / 3) * 2);
  display: grid;
  grid-template-columns: repeat(2,calc(50% - .5em));
  gap: 0 1em;
  &__img{
    @include imgCover(88.8%);
    padding-bottom: 0;
    height: 25em;
  }
  &__text,
  &__title{
    position: relative;
    z-index: 2;
  }
  &__text{
    margin-top: 1em;
  }
  .advantage-item2{
    z-index: 2;
    &__inner{
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 1.875em;
    }
  }
}


@media screen and (max-width: 1500px){
  .leader{
    padding: 0;
    &__inner{
      height: 41.75em;
    }
    &__content{
      max-width: 28.75em;
    }
  }
}


@media screen and (max-width: 992px){
  .leader{
    &__wrap{
      &:after{
        background: linear-gradient(90.57deg, rgba(0, 18, 51, 0.58) 0.557%,rgba(0, 18, 51, 0.58) 99.136%),linear-gradient(180.00deg, rgba(0, 51, 113, 0),rgba(0, 18, 51, 0.9) 64.493%);
      }
    }
    &__inner{
      height: unset;
      padding: 6em 0;
      display: block;
    }
    &__content{
      max-width: 100%;
    }
    &__items{
      margin-top: 2em;
      width: 100%;
      transform: unset !important;
      display: grid;
      grid-template-columns: repeat(2,calc(50% - .5em));
      gap: 1em;
    }
    .advantage-item2{
      margin: 0;
      &__inner{
        padding: .875em;
      }
    }
  }

  .marketing{
    &__items{
      margin-top: 2em;
      gap: 2em;
    }
  }

  .marketing-card{
    width: 100%;
    &__img{
      height: 23.5625em;
    }
    .advantage-item2{
      &__inner{
        padding: .875em;
      }
    }
  }
}


@media screen and (max-width: 576px){
  .leader{
    &__inner{
      padding: 4.5em 0;
    }
    &__desc{
      margin-top: 1em;
    }
    &__items{
      grid-template-columns: 100%;
    }
  }

  .marketing{
    padding: 4.5em 0;
    overflow: hidden;
  }

  .marketing-card{
    grid-template-columns: 100%;
    &__img{
      order: -1;
      height: 22.9375em;
    }
  }
}
