.header-catalog{
  height: 4.25em;
  &__btn{
    height: calc(100% + 1px);
    padding: 0 1em;
    position: relative;
    img{
      width: 1.3125em;
      margin-right: .5em;
    }
    &:before{
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      top: 100%;
      left: 0;
      margin-top: -1px;
      opacity: 0;
    }
  }
  &:hover{
    .catalog-menu{
      opacity: 1;
      pointer-events: unset;
    }
  }
}

.catalog-menu{
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: $lightGrey;
  overflow: hidden;
  color: $black;
  transition: all .3s;
  opacity: 0;
  pointer-events: none;
  &__inner{
    height: $vh;
    display: grid;
    grid-template-columns: 17.375em calc(100% - 17.375em);
    gap: 1.5em 0;
  }
  &__col{
    padding-top: 1.5em;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      width: 100vw;
      height: 100%;
      background-color: $white;
      left: 100%;
    }
  }
  &__col2{
    padding: 1.5em 0 1.5em 2em;
    height: calc(100 * var(--vh) - 4.25em);
    position: relative;
    z-index: 2;
    .tab-content{
      height: 100%;
    }
  }
  &__tab{
    padding-right: 1.5em;
    height: 3.5em;
    display: flex;
    align-items: center;
    color: $black;
    position: relative;
    transition: all .3s;

    &:before{
      pointer-events: none;
      content: '';
      width: 50vw;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-color: $darkBlue;
      transition: all inherit;
      opacity: 0;
    }
    &:hover{
      cursor: pointer;
    }
    &:hover,
    &._active{
      color: $white;
      svg{
        fill: $white;
      }
      &:before{
        opacity: 1;
      }
    }
    &-icon{
      width: 1.5em;
      height: 1.5em;
      margin-right: .75em;
    }
    &-arrow{
      margin-left: auto;
      width: 1em;
      height: 1em;
    }
    svg{
      fill: $black;
    }
    span,
    svg{
      position: relative;
      z-index: 2;
    }
  }
  &__items{
    height: 100%;
    padding-right: 1.25em;
    @include scroll;
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(3,calc(100% / 3 - 2em / 3));
    gap: 2em 1em;
  }
}

.catalog-group{
  &__top{
    display: flex;
    align-items: center;
  }
  &__btn{
    display: none;
  }
  &__icon{
    @include imgCover(100%);
    padding: 0;
    width: 2.5em;
    height: 2.5em;
    flex-shrink: 0;
    margin-right: 1em;
  }
  &__bottom{
    padding: .5em 0 0 3.5em;
    @media screen and (min-width: 993px){
      display: block !important;
    }
  }
  &__links{
    @include grid100gap(.5em);
    a{
      font-size: .875em;
      line-height: 143%;
      letter-spacing: 0.01em;
    }
  }
  .more-btn{
    margin-top: .5em;
  }
  &:not(._show-all-links){
    .catalog-group__links{
      li:nth-child(4)~li{
        display: none;
      }
    }
  }
  &._show-all-links{
    .more-btn{
      svg,
      ._view1{
        display: none;
      }
      ._view2{
        display: block;
      }
    }
  }
}

.more-btn{
  display: flex;
  align-items: center;
  color: $black-40;
  font-weight: 700;
  svg{
    width: .75em;
    height: .75em;
    margin-left: .25em;
    fill: $black-40;
  }
  ._view2{
    display: none;
  }
  span{
    border-bottom: 1px solid $black-40;
  }
  &--blue{
    color: $primary;
    span{
      border-color: $primary;
    }
    svg{
      fill: $primary;
    }
  }
  &:hover{
    color: $lightBlue;
    svg{
      fill: $lightBlue;
    }
    span{
      border-color: $lightBlue;
    }
  }
  &:active{
    color: $primary;
    svg{
      fill: $primary;
    }
    span{
      border-color: $primary;
    }
  }
}

.mob-catalog{
  padding-bottom: 3em;
  .catalog-menu{
    height: unset;
    opacity: 1;
    pointer-events: unset;
    background-color: transparent;
    position: static;
    &__inner{
      height: unset;
      grid-template-columns: 100%;
    }
    &__tabs{
      display: flex;
    }
    &__tab{
      background-color: $lightGrey;
      color: $black-64;
      height: 2.625em;
      justify-content: center;
      padding: 0 1em;
      margin-right: 1em;
      &:last-child{
        margin: 0;
      }
      svg{
        fill: $black-64;
        width: 1.125em;
        height: 1.125em;
        margin-right: .75em;
      }
      &-arrow{
        display: none;
      }
      &:before{
        display: none;
      }
      &:hover,
      &._active{
        color: $white;
        background-color: $primary;
        svg{
          fill: $white;
        }
      }
    }
    &__col2{
      padding: 0;
      height: unset;
    }
    &__items{
      padding: 0;
      overflow: unset;
      height: unset;
      display: block;
      column-count: 4;
      column-gap: 1em;
    }
  }
  .catalog-group{
    margin-bottom: 2em;
    display: inline-block;
    width: 100%;
    &__links{
      li{
        display: block !important;
      }
    }
    .more-btn{
      display: none;
    }
  }
}


@media screen and (max-width: 1500px){

}


@media screen and (max-width: 992px){
  .header-catalog{
    display: none;
  }

  .mob-catalog{
    .catalog-menu{
      &__items{
        column-count: 1;
      }
      .catalog-group{
        margin: 0;
      }
    }
  }

  .catalog-group{
    padding: .75em 0;
    &._open{
      .catalog-group__btn svg{
        transform: rotate(90deg);
      }
    }
    &__icon{
      width: 2.25em;
      height: 2.25em;
    }
    &__btn{
      width: 2.25em;
      height: 2.25em;
      @include center;
      margin-left: auto;
      svg{
        width: 1.25em;
        height: 1.25em;
        fill: $black;
      }
    }
    &__bottom{
      padding-top: 0;
      padding-left: 3.25em;
      display: none;
    }
    &__links{
      display: block;
      li{
        padding: .5em 0;
      }
      a{
        font-size: .8125em;
        line-height: 138%;
      }
    }
  }
}


@media screen and (max-width: 576px){
  .mob-catalog{
    .catalog-menu{
      &__tabs{
        display: block;
      }
      &__tab{
        margin: 0 0 1em;
        width: 100%;
      }
    }
  }
}
