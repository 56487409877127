@import 'vars';
html {
    width: 100%;
    &.fixed-body {
        overflow-y: hidden;
    }
}

.wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.m-page {
    //transform-style: preserve-3d;
    width: calc(100vw - (100vw - 100%));
    overflow-x: hidden;
    font-family: $font1;
    font-weight: 400;
    color: $black;
    font-size: calc(1500px * (16 / 1500));
    @media screen and (max-width: 1500px){
        font-size: calc(100vw * (16 / 1500));
    }
    &.fixed-body,
    &.fixed-body2{
        overflow-y: hidden;
        padding-right: var(--scrollWidth);
    }
}

.container {
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    padding: 0 3.75em;
}

a {
    &:hover {
        color: $lightBlue;
        &>svg{
            fill: $lightBlue;
        }
    }
    &:active {
        color: $primary;
        &>svg{
            fill: $primary;
        }
    }
}


@media screen and (max-width: 1500px){
    .m-page{
        font-size: calc(1280px * (16 / 1280));
        @media screen and (max-width: 1280px){
            font-size: calc(100vw * (16 / 1280));
        }
    }

    .container {
        max-width: 1280px;
    }
}


@media screen and (max-width: 992px){
    .m-page{
        font-size: calc(100vw * (16 / 768));
    }

    .container{
        padding: 0 1.5em;
    }
}


@media screen and (max-width: 576px){
    .m-page{
        font-size: calc(100vw * (16 / 375));
    }

    .container{
        padding: 0 1em;
    }
}


