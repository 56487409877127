.pagination{
  margin-top: 1em;
  display: flex;
  justify-content: center;
  a{
    @include center;
    height: 2.5em;
    min-width: 2.5em;
    padding: 0 .5em;
    border: 1px solid transparent;
    font-size: 1em;
    letter-spacing: 0.01em;
    font-weight: 700;
  }
  .active{
    a{
      border-color: $primary;
      color: $primary;
      pointer-events: none;
    }
  }
}


@media screen and (max-width: 992px){
  .pagination{
    a{
      font-size: .9375em;
      height: 2.13em;
      min-width: 2.13em;
    }
  }
}


@media screen and (max-width: 576px){

}
